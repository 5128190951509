export enum AssetStatus {
  processing = 0,
  needsCuration = 1,
  inCuration = 2,
  curationComplete = 3,
  requiresEdits = 4,
}

export enum AssetKind {
  book = 1,
  index = 2,
  platmap = 3,
}

export const assetListFilters = [
  {
    id: 0,
    param: 'status',
    value: AssetStatus.processing,
    label: 'books.processing',
  },
  {
    id: 1,
    param: 'status',
    value: AssetStatus.inCuration,
    label: 'books.incomplete',
  },
  {
    id: 2,
    param: 'status',
    value: AssetStatus.curationComplete,
    label: 'books.complete',
  },
];

export const AssetKindTab = {
  [AssetKind.book]: 'books',
  [AssetKind.index]: 'indices',
  [AssetKind.platmap]: 'plats_maps',
};

export enum AssetParams {
  documentNumber = 'documentNumber',
  documentType = 'documentType',
  grantee = 'grantee',
  grantor = 'grantor',
  startDate = 'startDate',
  endDate = 'endDate',
  bookNumber = 'bookNumber',
  township = 'township',
  range = 'range',
  section = 'section',
  city = 'city',
  subdivision = 'subdivision',
  block = 'block',
  lot = 'lot',
}

export const AssetSearchPropertyList = [
  {
    param: null,
    label: 'books.search_by',
  },

  {
    param: AssetParams.documentNumber,
    label: 'books.document_number',
  },
  {
    param: AssetParams.documentType,
    label: 'books.document_type',
  },
  {
    param: AssetParams.grantor,
    label: 'books.grantor',
  },
  {
    param: AssetParams.grantee,
    label: 'books.grantee',
  },

  {
    param: AssetParams.city,
    label: 'books.city',
  },
  {
    param: AssetParams.subdivision,
    label: 'books.subdivision',
  },
  {
    param: AssetParams.block,
    label: 'books.block',
  },
  {
    param: AssetParams.lot,
    label: 'books.lot',
  },

  {
    param: AssetParams.township,
    label: 'books.township',
  },
  {
    param: AssetParams.range,
    label: 'books.range',
  },
  {
    param: AssetParams.section,
    label: 'books.section',
  },

  {
    param: AssetParams.startDate,
    label: 'books.start_date',
    placeholder: 'mm/dd/yyyy',
    pattern: /(0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])[\/](1[7-9]|20)\d\d/,
  },
  {
    param: AssetParams.endDate,
    label: 'books.end_date',
    placeholder: 'mm/dd/yyyy',
    pattern: /(0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])[\/](1[7-9]|20)\d\d/,
  },
  {
    param: AssetParams.bookNumber,
    label: 'books.book_number',
  },
];
