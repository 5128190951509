import { removeEmptyProperties } from '@app/shared/helpers';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, catchError, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver/dist/FileSaver';
import { User } from '@app/shared/users/users.models';
import { Download } from '@app/shared/downloads/downloads.models';
import {
  ResetPassword,
  ChangePassword,
} from '@app/shared/users/users.constants';
import { StripeToken } from '@app/shared/billing-profiles/billing-profiles.constants';
import { App } from '@app/app';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage } from '@app/app.actions';
import { LoggerService } from '@app/core';
import { InvoicesAPI } from '@app/invoices/invoices';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  private get apiUrl(): string {
    return environment.apiUrl;
  }

  private userModel(user: User): User {
    return new User(user);
  }

  private removeEmptyProperties(params): { [key: string]: string } {
    return removeEmptyProperties(params);
  }

  private saveAs(blob: Blob, title: string): saveAs {
    return saveAs(blob, title);
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private translateService: TranslateService
  ) {}

  public getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiUrl}/users`).pipe(
      map((users) => users.map((user) => this.userModel(user))),
      catchError(this.handleError)
    );
  }

  public getUserById(id: string): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/users/${id}`).pipe(
      map((user) => this.userModel(user)),
      catchError(this.handleError)
    );
  }

  public addUser(user: User): Observable<User> {
    return this.http
      .post<User>(`${this.apiUrl}/users`, user)
      .pipe(map(this.userModel), catchError(this.handleError));
  }

  public updateUser(user: User): Observable<User> {
    return this.http
      .put<User>(`${this.apiUrl}/users/${user.id}`, user)
      .pipe(map(this.userModel), catchError(this.handleError));
  }

  public patchUser(user: Partial<User>): Observable<User> {
    return this.http
      .patch<User>(`${this.apiUrl}/users/${user.id}`, user)
      .pipe(map(this.userModel), catchError(this.handleError));
  }

  public changePassword(credentials: ChangePassword): Observable<User> {
    return this.patchUser(credentials);
  }

  public resetPassword(passwords: ResetPassword): Observable<any> {
    return this.http
      .post<ResetPassword>(`${this.apiUrl}/users/reset_password`, passwords)
      .pipe(catchError(this.handleError));
  }

  public deleteUserById(id: string): Observable<User> {
    return this.http
      .delete<User>(`${this.apiUrl}/users/${id}`)
      .pipe(map(this.userModel), catchError(this.handleError));
  }

  public resendValidationEmail(user: User): Observable<User> {
    return this.http
      .post<User>(`${this.apiUrl}/users/resend_validation_email`, user)
      .pipe(map(this.userModel), catchError(this.handleError));
  }

  public getDownloadsByUserId(
    userId: string,
    parameters
  ): Observable<App.List.Results> {
    return this.http
      .get<App.List.Results>(`${this.apiUrl}/users/${userId}/pagedownload`, {
        params: this.removeEmptyProperties(parameters),
      })
      .pipe(
        catchError((error) => {
          const message = this.translateService.instant(
            'downloads.get_downloads_error'
          );
          this.store.dispatch(new ErrorMessage(message));
          return this.handleError(error);
        })
      );
  }

  public getInvoicesByUserId(
    userId: string,
    parameters
  ): Observable<App.List.Results> {
    return this.http
      .get<App.List.Results>(`${this.apiUrl}/users/${userId}/invoices`, {
        params: this.removeEmptyProperties(parameters),
      })
      .pipe(
        catchError((error) => {
          const message = this.translateService.instant(
            'invoices.get_invoices_error'
          );
          this.store.dispatch(new ErrorMessage(message));
          return this.handleError(error);
        })
      );
  }

  public getInvoice(
    userId: string,
    invoiceId: string
  ): Observable<InvoicesAPI.Invoice> {
    return this.http
      .get<InvoicesAPI.Invoice>(
        `${this.apiUrl}/users/${userId}/invoices/${invoiceId}/summary`
      )
      .pipe(
        catchError((error) => {
          const message = this.translateService.instant(
            'invoices.get_invoices_error'
          );
          this.store.dispatch(new ErrorMessage(message));
          return this.handleError(error);
        })
      );
  }

  public getDownloadById(
    userId: string,
    downloadId: string
  ): Observable<Download> {
    return this.http
      .get<Download>(
        `${this.apiUrl}/users/${userId}/pagedownload/${downloadId}`
      )
      .pipe(catchError(this.handleError));
  }

  public regenerateDownloadById(
    userId: string,
    downloadId: string
  ): Observable<Download> {
    return this.http
      .put<Download>(
        `${this.apiUrl}/users/${userId}/pagedownload/${downloadId}`,
        null
      )
      .pipe(catchError(this.handleError));
  }

  public deleteDownloadById(
    userId: string,
    downloadId: string
  ): Observable<Download> {
    return this.http
      .delete<Download>(
        `${this.apiUrl}/users/${userId}/pagedownload/${downloadId}`
      )
      .pipe(catchError(this.handleError));
  }

  public downloadPages(userId: string, download: Download) {
    return this.http
      .get(`${this.apiUrl}/users/${userId}/pagedownload/${download.id}`, {
        responseType: 'blob',
        headers: { Accept: 'application/pdf' },
      })
      .pipe(
        tap((blob) => this.saveAs(blob, `${download.downloadTitle}.pdf`)),
        catchError(this.handleError)
      );
  }

  public resetTerms(): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/users/reset_terms`, {})
      .pipe(catchError(this.handleError));
  }

  public duplicateEmailCheck(email: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/email_check`, { email });
  }

  public validatePassword(password: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/validate_password`, {
      password,
    });
  }

  public changeStripeToken(token: StripeToken): Observable<any> {
    return this.http
      .put<any>(`${this.apiUrl}/payment`, token)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    LoggerService.error(error);
    return observableThrowError(error);
  }
}
