import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  Directive,
  OnChanges,
  Input,
  SimpleChanges,
  HostListener,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import { ResourceService } from '@app/shared/services/resource/resource.service';

@Directive({
  selector: '[vaultAsyncSrc]',
})
export class AsyncSrcDirective implements OnChanges {
  private objectUrl: string;

  @Input('vaultAsyncSrc') public asyncSrc: string;
  @Input() public dynamic = false;

  @HostBinding('src') public src: SafeUrl;

  @HostListener('load', ['$event'])
  onLoad() {
    URL.revokeObjectURL(this.objectUrl);
  }

  constructor(
    private resourceService: ResourceService,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges({ asyncSrc: { currentValue, previousValue } }: SimpleChanges) {
    if (currentValue) {
      if (currentValue !== previousValue) {
        this.getImage(currentValue);
      }
      if (!this.dynamic) {
        this.changeDetectorRef.detach();
      }
    }
  }

  private getImage(asyncSrc: string): void {
    this.resourceService.getBlob(asyncSrc).subscribe((imageBlob: Blob) => {
      this.objectUrl = URL.createObjectURL(imageBlob);
      this.setSrc(this.objectUrl);
    });
  }

  private setSrc(objectUrl: string): void {
    this.src = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
    this.changeDetectorRef.detectChanges();
  }
}
