import { AbstractControl } from '@angular/forms';

export const PHONE_REGEXP = /^\([2-9]\d{2}\) \d{3}-\d{4}$/;
export const PHONE_ERROR = {
  name: 'phone',
  text: 'app.field_phone_format',
  rules: ['touched']
};

export function PhoneValidator(input: AbstractControl): any {
  if (input.pristine) {
    return null;
  }

  input.markAsTouched();

  if (input.value === '') {
    return null;
  }

  if (PHONE_REGEXP.test(input.value)) {
    return null;
  }

  return {
    phone: true
  };
}
