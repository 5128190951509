import { Directive, HostListener, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][vaultDateMask]'
})
export class DateMaskDirective implements AfterViewInit {
  constructor(public ngControl: NgControl) {}

  ngAfterViewInit() {
    if (this.ngControl.control.value) {
      this.onInputChange(this.ngControl.control.value);
    }
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: string) {
    this.onInputChange(event);
  }

  onInputChange(event: string) {
    if (!event) {
      return;
    }

    const val = event.replace(/\D/g, '').match(/(\d{0,4})(\d{0,2})(\d{0,2})/);

    const formattedValue = !val[2]
      ? val[1]
      : `${val[1]}-${val[2]}` + (val[3] ? `-${val[3]}` : ``);

    this.ngControl.valueAccessor.writeValue(formattedValue);
  }
}
