import { Component, HostBinding, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vault-layout-unauthorized',
  templateUrl: './layout-unauthorized.component.html',
  styleUrls: ['./layout-unauthorized.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutUnauthorizedComponent implements OnInit {
  @HostBinding('class') componentClass = 'vault-flex-layout';
  @Input() noContainerPadding = null;

  public containerClass = null;

  ngOnInit() {
    if (!this.noContainerPadding) {
      this.containerClass = 'container-fluid';
    }
  }
}

