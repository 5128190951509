import { Component, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vault-actions-footer',
  templateUrl: './actions-footer.component.html',
  styleUrls: ['./actions-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsFooterComponent {
  @HostBinding('class') componentClass = 'vault-footer';
}
