import { Pipe, PipeTransform } from '@angular/core';

export interface ISearchParams {
  value: string;
  match?: string[];
}

@Pipe({
  name: 'searchFor'
})
export class SearchForPipe implements PipeTransform {

  transform(items: Array<any>, parameters: ISearchParams): Array<any> {

    if (!items || !Array.isArray(items) || !parameters || !parameters.value) { return; }

    const searchStr = `${parameters.value}`.toLowerCase();
    const matchKeys = parameters.match;

    return items.filter( (item, index, array) => {
      return matchKeys.some( (key) => {
        return `${item[key]}`.toLowerCase().indexOf(searchStr) !== -1;
      });
    });

  }
}
