<div class="card text-white bg-danger" *ngIf="isDevMode">
  <div class="card-header d-flex cursor-pointer" (click)="toggle()">
    <div class="flex-grow-1">{{ label }}</div>
    <div>
      <i class="fa fa-{{ icon }}"></i>
    </div>
  </div>

  <div class="card-body" *ngIf="!closed" [@collapseExpand]>
    <pre><ng-content></ng-content></pre>
  </div>
</div>
