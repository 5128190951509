<input
  id="textSearch"
  autocomplete="off"
  type="text"
  class="form-control"
  [formControl]="control"
  [placeholder]="placeholder"
/>

<i class="fas fa-search fa-fw" *ngIf="!hasValue && !isLoading"></i>

<i
  class="fas fa-times fa-fw"
  *ngIf="hasValue && !isLoading"
  (click)="clear()"
></i>

<i class="fa fa-spinner fa-pulse nile-blue-color" *ngIf="isLoading"></i>
