import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * A service to asynchronously load blob resources such as images and PDF files
 */
@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(private http: HttpClient) {}

  public getBlob(resourceUrl: string): Observable<Blob> {
    return this.http.get(resourceUrl, { responseType: 'blob' });
  }
}
