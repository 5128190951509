import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { UsersApiService } from '@app/shared/services/users/users-api.service';

@Injectable()
export class VerifyEmailValidator {
  debouncer: any;

  constructor(private usersApiService: UsersApiService) {}

  public checkEmail(control: UntypedFormControl): any {
    clearTimeout(this.debouncer);

    return new Promise((resolve) => {
      this.debouncer = setTimeout(() => {
        this.usersApiService.duplicateEmailCheck(control.value).subscribe(
          (res) => {
            resolve({ incorrect: true });
          },
          (err) => {
            resolve(null);
          }
        );
      }, 500);
    });
  }
}
