import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { County } from '@app/shared/counties/counties.models';

@Injectable({
  providedIn: 'root',
})
export class CountyService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public getCountyModel(county: County): County {
    if (!county) {
      return undefined;
    }
    return {
      ...county,
      $digitalIndexStart: this.getFormattedDate(county.digitalIndexStart),
      $digitalIndexEnd: this.getFormattedDate(county.digitalIndexEnd),
    };
  }

  private getFormattedDate(date: string): string {
    if (!date) {
      return;
    }
    return formatDate(date, 'MMM d, y', this.locale);
  }
}
