<hr>

<p>
  <small>
    <a href="mailto:support@vaultside.com?subject=Support Request" translate>app.help</a> |
    <a href translate routerLink="/terms_of_use">app.terms_of_use</a> |
    <a href translate routerLink="/privacy_policy">app.privacy_policy</a>
  </small>
</p>

<p class="app-version" *ngIf="version$|async"><small>Version: {{version$|async}}</small></p>
