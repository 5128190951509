
import { throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, catchError } from 'rxjs/operators';

import { County } from '@app/shared/counties/counties.models';
import { BillingProfile } from '@app/shared/billing-profiles/billing-profiles.models';
import { StripeToken } from '@app/shared/billing-profiles/billing-profiles.constants';
import { LoggerService } from '@app/core/services/logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class BillingProfilesApiService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.apiUrl;
  }

  public getBillingProfilesByCounty(county: County): Observable<BillingProfile[]> {
    return this.http
      .get<BillingProfile[]>(`${this.apiUrl}/billing_profiles?county=${county.id}`)
      .pipe(
        map(profiles => profiles.map( (profile) => new BillingProfile(profile))),
        catchError(this.handleError)
      );
  }

  public getBillingProfileById(id: string): Observable<BillingProfile> {
    return this.http
      .get<BillingProfile>(`${this.apiUrl}/billing_profiles/${id}`)
      .pipe(
        map( profile => new BillingProfile(profile) ),
        catchError(this.handleError)
      );
  }

  public createBillingProfile(profile: BillingProfile): Observable<BillingProfile> {
    return this.http
      .post<BillingProfile>(`${this.apiUrl}/billing_profiles`, profile)
      .pipe(
        map( prfl => new BillingProfile(prfl) ),
        catchError(this.handleError)
      );
  }

  public updateBillingProfile(profile: BillingProfile): Observable<BillingProfile> {
    return this.http
      .put<BillingProfile>(`${this.apiUrl}/billing_profiles/${profile.id}`, profile)
      .pipe(
        map( prfl => new BillingProfile(prfl) ),
        catchError(this.handleError)
      );
  }

  public deleteBillingProfileById(id: string): Observable<BillingProfile> {
    return this.http
      .delete<BillingProfile>(`${this.apiUrl}/billing_profiles/${id}`)
      .pipe(
        map( profile =>  new BillingProfile(profile) ),
        catchError(this.handleError)
      );
  }

  public processSubscriptionPayment(token: StripeToken): Observable<{
    paymentToken: string;
    countyId: string;
    subscriptionToken: string;
  }> {
    return this.http
      .post<any>(`${this.apiUrl}/payment/accept`, token)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: Response | any) {
    LoggerService.error(error);
    return observableThrowError(error);
  }

}
