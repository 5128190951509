import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToQueryString'
})
export class ObjectToQueryStringPipe implements PipeTransform {

  transform(obj: any): any {
    return Object
      .keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  }

}
