import { Component, Input, Output, HostBinding, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vault-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StageComponent {

  @HostBinding('class') componentClass = 'vault-stage';

  @Input() title: string;
  @Input() backLabel: string;

  @Output() back: EventEmitter<null> = new EventEmitter();

  public goBack() {
    this.back.emit();
  }

}
