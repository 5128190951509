import { Component, Input, forwardRef, ChangeDetectionStrategy, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// https://netbasal.com/angular-custom-form-controls-made-easy-4f963341c8e2
// https://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html
// https://alligator.io/angular/custom-form-control/
// https://blog.angularindepth.com/never-again-be-confused-when-implementing-controlvalueaccessor-in-angular-forms-93b9eee9ee83

export type TtoggleSwitchLabels =
  'on:off' |
  'true:false' |
  'yes:no';

@Component({
  selector: 'vault-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true
    }
  ]
})
export class ToggleSwitchComponent implements ControlValueAccessor {

  @ViewChild('toggleSwitch', { static: true }) toggleSwitch;

  @Input() public trueValue: any = true;
  @Input() public falseValue: any = false;
  @Input() public trueLabel = 'Yes';
  @Input() public falseLabel = 'No';
  @Input() public disabled = false;

  private value;

  constructor(
    private renderer: Renderer2
  ) {}

  public get isOn(): boolean {
    return this.value === this.trueValue;
  }

  public toggle() {
    if (this.disabled) { return; }
    this.value = (this.value === this.trueValue) ? this.falseValue : this.trueValue;
    this.propogateChange(this.value);
  }

  writeValue(value: any) {
    this.value = (value === undefined) ? this.falseValue : value;
  }

  propogateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propogateChange = fn;
  }

  registerOnTouched() {/* keep! */}

  setDisabledState(isDisabled: boolean): void {
    const toggle = this.toggleSwitch.nativeElement;
    const action = isDisabled ? 'addClass' : 'removeClass';
    this.renderer[action](toggle, 'disabled');
  }
}
