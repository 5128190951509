import { AbstractControl } from '@angular/forms';

export const YYYYMMDD_REGEXP = /^([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
export const YYYYMMDD_ERROR = {
  name: 'date',
  text: 'app.field_date_format',
  rules: ['touched']
};

export function DateValidator(input: AbstractControl): any {
  if (input.pristine) {
    return null;
  }

  input.markAsTouched();

  if (input.value === '') {
    return null;
  }

  if (YYYYMMDD_REGEXP.test(input.value)) {
    return null;
  }

  return {
    date: true
  };
}
