import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizer'
})
export class SanitizerPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(url: any): null|SafeUrl|SafeResourceUrl {
    if (!url) { return null; }
    // return this.sanitizer.bypassSecurityTrustUrl(url); // image
    return this.sanitizer.bypassSecurityTrustResourceUrl(url); // iframe/script
  }

}
