import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FilterDefinition } from '@app/shared/app/app.constants';

@Component({
  selector: 'vault-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFilterComponent {

  @Input() filters: FilterDefinition[];

  @Output() addFilter: EventEmitter<FilterDefinition> = new EventEmitter();

  public onAddFilter(filter: FilterDefinition) {
    this.addFilter.emit(filter);
  }
}
