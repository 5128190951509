import { subscriptionDuration } from '../billing-profiles/billing-profiles.constants';

export const subscriptionDurationOptions = [
  {
    type: subscriptionDuration.hour,
    label: 'subscriptions.duration_hour',
    expiration: 'subscriptions.hour_expiration',
    planLabel: 'subscriptions.hourly_plan'
  }, {
    type: subscriptionDuration.day,
    label: 'subscriptions.duration_day',
    expiration: 'subscriptions.day_expiration',
    planLabel: 'subscriptions.daily_plan'
  }, {
    type: subscriptionDuration.week,
    label: 'subscriptions.duration_week',
    expiration: 'subscriptions.week_expiration',
    planLabel: 'subscriptions.weekly_plan'
  }, {
    type: subscriptionDuration.month,
    label: 'subscriptions.duration_month',
    expiration: 'subscriptions.month_expiration',
    planLabel: 'subscriptions.monthly_plan'
  }, {
    type: subscriptionDuration.year,
    label: 'subscriptions.duration_year',
    expiration: 'subscriptions.year_expiration',
    planLabel: 'subscriptions.yearly_plan'
  }
];
