<div ngbDropdown class="d-inline-block">

  <button ngbDropdownToggle translate class="btn btn-outline-primary">app.filter</button>

  <div ngbDropdownMenu>
    <button
      *ngFor="let filter of filters"
      class="dropdown-item"
      (click)="onAddFilter(filter)">
        {{filter.label|translate}}
    </button>
  </div>

</div>

