import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamic'
})
export class DynamicPipe implements PipeTransform {

  constructor(
    private injector: Injector,
  ) {}

  transform(value: any, pipeToken: any, args?: any): any {

    if (!value) { return; }

    if (!pipeToken) {
      return value;
    } else {
      const pipe = this.injector.get(pipeToken);
      return pipe.transform(value, ...args);
    }

  }

}
