import { Pipe, PipeTransform } from '@angular/core';

import { subscriptionDurationOptions } from '@app/shared/subscriptions/subscriptions.constants';

@Pipe({
  name: 'subscriptionDuration'
})
export class SubscriptionDurationPipe implements PipeTransform {

  transform(value: any): any {
    return subscriptionDurationOptions.find( (option) => {
      return +option.type === +value;
    }).label;
  }

}
