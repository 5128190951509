import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { AppStateModel } from '@app/shared';
import { LoggerService } from '@app/core/services/logger/logger.service';
import * as Actions from '@app/app.actions';

@Injectable({
  providedIn: 'root'
})
export class CanActivateDisabledCountyGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree {

    const ctx = this.store.selectSnapshot<AppStateModel>( state => state.app );

    if (!ctx.county.enabled && ctx.user.$isViewOnly) {
      const error = new Error('User not authorized to view disabled counties');
      LoggerService.error(error);
      this.store.dispatch( new Actions.ErrorMessage('app.access_prohibited') );
      return this.router.parseUrl(`/counties`);
    } else {
      return true;
    }
  }

}
