import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import stringify from 'json-stringify-safe';
import * as Sentry from '@sentry/angular';
import { SeverityLevel } from '@sentry/types';
import { User } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private static logLocal(
    message: any,
    type: LogType,
    defaultConfig: TrackingConfig
  ) {
    if (environment.allowConsoleLogging) {
      if (typeof message === 'string') {
        console[type](message);
      } else {
        console[type](stringify(message, null, 2));
      }
    }
    return {
      track: (customConfig: TrackingConfig = {}) => {
        const config = Object.assign(defaultConfig, customConfig);
        Sentry.withScope((scope) => {
          if (config.extras) {
            Object.keys(config.extras).forEach((k) =>
              scope.setContext(`${k}`, config.extras[k])
            );
          }
          if (config.tags) {
            Object.keys(config.tags).forEach((k) =>
              scope.setTag(`${k}`, config.tags[k])
            );
          }
          scope.setLevel(config.level);
          Sentry.captureMessage(message);
        });
      },
    };
  }

  public static addBreadcrumb(config: Sentry.Breadcrumb): void {
    Sentry.addBreadcrumb(config);
  }

  public static error(message: any) {
    Sentry.captureException(message);
    const config = {
      level: 'error' as SeverityLevel,
    };
    return LoggerService.logLocal(message, LogType.error, config);
  }

  public static log(message: any) {
    const config = {
      level: 'log' as SeverityLevel,
    };
    return LoggerService.logLocal(message, LogType.log, config);
  }

  public static info(message: any) {
    const config = {
      level: 'info' as SeverityLevel,
    };
    return LoggerService.logLocal(message, LogType.info, config);
  }

  public static warn(message: any) {
    const config = {
      level: 'warning' as SeverityLevel,
    };
    return LoggerService.logLocal(message, LogType.warn, config);
  }
}

export enum LogType {
  error = 'error',
  info = 'info',
  warn = 'warn',
  log = 'log',
}

export interface TrackingConfig {
  user?: Partial<User>;
  level?: SeverityLevel;
  tags?: { [key: string]: any };
  extras?: { [key: string]: any };
}
