import { Component, HostBinding, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vault-layout-trowser',
  templateUrl: './layout-trowser.component.html',
  styleUrls: ['./layout-trowser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutTrowserComponent {

  @Input() public title: string;

  @HostBinding('class') componentClass = 'vault-flex-layout';

}
