import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { State } from '../../states/states.models';
import { LoggerService } from '@app/core/services/logger/logger.service';
import { environment } from '@env/environment';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class StatesApiService {
  constructor(private http: HttpClient) {}

  public getAllStates(): Observable<State[]> {
    return this.http
      .get<State[]>(`${apiUrl}/states`)
      .pipe(catchError(this.handleError));
  }

  public updateState(state: State): Observable<State> {
    return this.http
      .put<State>(`${apiUrl}/states/${state.id}`, state)
      .pipe(catchError(this.handleError));
  }

  public toggleState(state: State): Observable<State> {
    state.enabled = !state.enabled;
    return this.updateState(state);
  }

  private handleError(error: Response | any) {
    LoggerService.error(error);
    return observableThrowError(error);
  }
}
