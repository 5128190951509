import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FilterDefinition } from '@app/shared/app/app.constants';

@Component({
  selector: 'vault-filter-shortcuts',
  templateUrl: './filter-shortcuts.component.html',
  styleUrls: ['./filter-shortcuts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterShortcutsComponent {

  @Input() filters: FilterDefinition[];

  @Output() setFilter: EventEmitter<FilterDefinition> = new EventEmitter();

  public onSetFilter(filter: FilterDefinition) {
    this.setFilter.emit(filter);
  }

}
