import { AssetKind, AssetStatus } from '@app/shared';

export class Book {
  public id?: string | null = null;
  public kind: AssetKind;
  public index?: boolean;
  public bookNumber?: string;
  public number?: string;
  public originalFileName?: string;
  public status?: AssetStatus;
  public curatedAt?: string | Date;
  public updatedAt?: string | Date;
  public state?: string;
  public county?: string;
  public filesize?: number;
  public notes?: string;
  public startPage?: string;
  public endPage?: string;
  public startDocRecordingDate?: string;
  public endDocRecordingDate?: string;
  public startDocNum?: string;
  public endDocNum?: string;
  public searchText?: string;

  constructor(book?: Book) {
    if (book) {
      Object.assign(this, book);
    }
  }
}

export class Page {
  public id: string = null;
  public book: string;
  public tnWidth?: number;
  public tnHeight?: number;
  public pgNumber: string | number;
  public pgIndex: number;
  public documentNumber: string;
  public text = '';
  public tab?: string;
  public pages?: number;
  public blank?: boolean;
  public missing?: boolean;
  public updatedAt?: string;

  public $isFocused?: boolean;
  public $isSelected?: boolean;

  constructor(page?: Page, patch?: Partial<Page>) {
    if (page) {
      Object.assign(this, patch, page);
    }
  }

  public patch?(patch: Partial<Page>): Page {
    return Object.assign(this, patch);
  }
}

export interface BookMapping {
  bookId: string;
  pageId: string;
}

export interface UnmappedAssets {
  documentNumber?: string;
  bookType?: string;
  bookNumber?: string;
  bookPageNumber?: string;
}
