import { App } from '../app/app';

// $ npm run uat

export const environment: App.Environment = {
  production: true,
  apiUrl: '',
  wsUrl: 'wss://uat.vaultside.com/sockets',
  stripeKey: 'pk_test_WndvycKBW2lKuLxgcgEFeLO9',
  sentryKey: 'ed5a5fbce6ba43288fa71766de5f372d',
  sentryProjectId: '1310463',
  googleAnalytics: false,
  showDebugData: false,
  allowConsoleLogging: true,
  enforceDataIntegrity: false,
};
