<div ngbDropdown placement="bottom-right">

  <button class="user-toggle" ngbDropdownToggle>
    <i class="fas fa-user"></i>
  </button>

  <div ngbDropdownMenu>

    <a class="dropdown-item" routerLink="/account">
      <i class="fas fa-user-edit fa-fw"></i>
      {{'app.my_account'|translate}}
    </a>

    <a class="dropdown-item" routerLink="/change_password">
      <i class="fas fa-key fa-fw"></i>
      {{'app.change_password'|translate}}
    </a>

    <div class="dropdown-divider"></div>

    <button class="dropdown-item" (click)="logout()">
      <i class="fas fa-sign-out-alt fa-fw"></i>
      {{'app.logout'|translate}}
    </button>

  </div>

</div>
