<vault-page-header>
  <vault-logo></vault-logo>
</vault-page-header>

<ng-content select="vault-stage"></ng-content>

<div class="vault-flex-content">
  <div class="vault-content auto-scroll">
    <div [ngClass]="containerClass">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<ng-content select="vault-actions-footer"></ng-content>
