import { NgModule } from '@angular/core';

/**
 * Modules
 */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { SharedComponentsModule } from '@app/shared/components/components.module';
import { SharedDirectivesModule } from '@app/shared/directives/directives.module';
import { SharedPipesModule } from '@app/shared/pipes/pipes.module';

export const sharedModules = [
  CommonModule,
  FormsModule,
  NgbModule,
  ReactiveFormsModule,
  SharedComponentsModule,
  SharedDirectivesModule,
  SharedPipesModule,
  TranslateModule,
];

/**
 * Guards
 */
import { CanActivateViaAuthGuard } from './guards/can-activate-via-auth/can-activate-via-auth.guard';
import { CanActivateIfAdminGuard } from './guards/can-activate-if-admin/can-activate-if-admin.guard';
import { RedirectToCountyTabGuard } from './guards/redirect-to-county-tab/redirect-to-county-tab.guard';
import { RedirectToStartPageGuard } from './guards/redirect-to-start-page/redirect-to-start-page.guard';
import { RedirectToSubscriptionsGuard } from './guards/redirect-to-subscriptions/redirect-to-subscriptions.guard';
import { RequireCountySelectedGuard } from './guards/require-county-selected/require-county-selected.guard';

export const coreGuards = [
  CanActivateIfAdminGuard,
  CanActivateViaAuthGuard,
  RedirectToCountyTabGuard,
  RedirectToStartPageGuard,
  RedirectToSubscriptionsGuard,
  RequireCountySelectedGuard,
];

/**
 * Services
 */
import { AuthApiService } from './services/auth/auth-api.service';
import { BillingProfilesApiService } from './services/billing-profiles/billing-profiles-api.service';
import { CountiesApiService } from './services/counties/counties-api.service';
import { CountyService } from './services/counties/county.service';
import { TranslationLoaderService } from './services/translation-loader/translation-loader.service';
import { JwtRequestInterceptorService } from './services/jwt-request-interceptor/jwt-request-interceptor.service';
import { StorageService } from './services/storage/storage.service';
import { PSPDFKitLicenseService } from './services/pspdfkit-license/pspdfkit-license.service';
import { ResourceService } from './services/resource/resource.service';
import { SubscriptionRequestInterceptorService } from './services/subscription-request-interceptor/subscription-request-interceptor.service';
import { UsersApiService } from './services/users/users-api.service';

export const sharedServices = [
  AuthApiService,
  BillingProfilesApiService,
  CountiesApiService,
  CountyService,
  TranslationLoaderService,
  JwtRequestInterceptorService,
  SubscriptionRequestInterceptorService,
  StorageService,
  PSPDFKitLicenseService,
  ResourceService,
  UsersApiService,
];

@NgModule({
  imports: [...sharedModules],
  exports: [...sharedModules],
  providers: [...coreGuards, ...sharedServices],
})
export class SharedModule {}
