import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Pipes
 */
import { ApplyFiltersPipe } from './apply-filters/apply-filters.pipe';
import { BillingProfileDurationPipe } from './billing-profile-duration/billing-profile-duration.pipe';
import { DynamicPipe } from './dynamic/dynamic.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { ObjectToQueryStringPipe } from './object-to-query-string/object-to-query-string.pipe';
import { PaginationPipe } from './pagination/pagination.pipe';
import { PhoneNumberPipe } from './phone-number/phone-number.pipe';
import { SanitizerPipe } from './sanitizer/sanitizer.pipe';
import { SearchForPipe } from './search-for/search-for.pipe';
import { SortByPipe } from './sort-by/sort-by.pipe';
import { StringifyLegalDescriptionPipe } from './stringify-legal-description/stringify-legal-description.pipe';
import { SubscriptionDurationPipe } from './subscription-duration/subscription-duration.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { UserRoleLabelPipe } from './user-role-label/user-role-label.pipe';

export const sharedPipes = [
  ApplyFiltersPipe,
  BillingProfileDurationPipe,
  DynamicPipe,
  FileSizePipe,
  ObjectToQueryStringPipe,
  PaginationPipe,
  PhoneNumberPipe,
  SanitizerPipe,
  SearchForPipe,
  SortByPipe,
  StringifyLegalDescriptionPipe,
  SubscriptionDurationPipe,
  TruncatePipe,
  UserRoleLabelPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...sharedPipes],
  exports: [...sharedPipes],
  providers: [...sharedPipes],
})
export class SharedPipesModule {}
