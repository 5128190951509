import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { PSPDFKitLicenseKey } from './pspdfkit-license';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PSPDFKitLicenseService {
  // NOTE: this identifier may change when PSPDFKit releases a major update
  public static identifier = 'PS240118-96013-45524';

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window
  ) {}

  public getLicenseKey() {
    return this.http
      .get<PSPDFKitLicenseKey[]>('/assets/pspdfkit-license-keys.json')
      .pipe(
        map((bundle) =>
          bundle.find(
            ({ identifier }) => identifier === PSPDFKitLicenseService.identifier
          )
        ),
        map(({ bundle_ids }) =>
          bundle_ids.find(
            ({ bundle_id }) => bundle_id === this.window.location.hostname
          )
        ),
        map(({ license_key }) => license_key)
      );
  }
}
