import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { UsersApiService } from '@app/shared/services/users/users-api.service';

@Injectable()
export class VerifyPasswordValidator {
  debouncer: any;

  constructor(private usersApiService: UsersApiService) {}

  public checkPassword(control: UntypedFormControl): any {
    clearTimeout(this.debouncer);

    return new Promise((resolve) => {
      this.debouncer = setTimeout(() => {
        this.usersApiService.validatePassword(control.value).subscribe(
          (res) => {
            if (res.ok) {
              resolve(null);
            }
          },
          (err) => {
            resolve({ invalid: true });
          }
        );
      }, 500);
    });
  }
}
