import { Inject, Injectable } from '@angular/core';
import { AppStateModel } from '@app/shared';
import {
  Stringify,
  STRINGIFY,
} from '@app/shared/vendor/stringify/stringify.provider';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    public store: Store,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(STRINGIFY) private stringify: Stringify
  ) {}

  public set(key: string, value: any): void {
    const data = this.getStorage();
    data[key] = value;
    this.localStorage.setItem(this.cacheKey, this.stringify(data));
  }

  public get(key: string): any {
    const data = this.getStorage();
    return data?.[key] || undefined;
  }

  public remove(key: string): void {
    const data = this.getStorage();
    delete data[key];
    this.localStorage.setItem(this.cacheKey, this.stringify(data));
  }

  private getStorage(): { [key: string]: any } {
    const oldDataString = this.localStorage.getItem(this.cacheKey);
    return (oldDataString && JSON.parse(oldDataString)) || {};
  }

  private get userId(): string {
    return this.store.selectSnapshot<AppStateModel>((state) => state.app).user
      .id;
  }

  private get cacheKey(): string {
    return `USER_${this.userId}`;
  }
}

// cleanup
// delete tutorial-view-count
// delete ASSETS_SEARCH_CACHE
