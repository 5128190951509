<a translate routerLink="/counties" routerLinkActive="active">app.counties</a>

<a translate routerLink="/users" routerLinkActive="active" *ngIf="user.$isAdmin"
  >app.users</a
>

<a translate id="pdfDownloads" routerLink="/downloads" routerLinkActive="active"
  >app.pdf_downloads</a
>

<a
  translate
  id="invoices"
  routerLink="/invoices"
  routerLinkActive="active"
  *ngIf="user.$isViewOnly && !user.$isCourthouseUser"
  >app.invoices</a
>
