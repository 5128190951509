import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Book,
  BookMapping,
  Page,
  UnmappedAssets,
} from '@app/shared/books/books.models';
import { Download } from '@app/shared/downloads/downloads.models';
import { BooksApiService } from '@app/core/services/books/books-api.service';
import { App } from '@app/app';

@Injectable({
  providedIn: 'root',
})
export class BooksDataService {
  constructor(private api: BooksApiService) {}

  /**
   * Books
   */

  public deleteBookById(id: string): Observable<Book> {
    return this.api.deleteBookById(id);
  }

  public updateBook(instrument: Book): Observable<Book> {
    return this.api.updateBook(instrument);
  }

  public downloadBookInventory(countyId: string): Observable<any> {
    return this.api.downloadBookInventory(countyId);
  }

  public getUnmappableDocuments(id: string): Observable<UnmappedAssets[]> {
    return this.api.getUnmappableDocuments(id);
  }

  public getUnmappedDocuments(id: string): Observable<UnmappedAssets[]> {
    return this.api.getUnmappedDocuments(id);
  }

  public downloadUnmappableDocuments(id: string): Observable<Blob> {
    return this.api.downloadUnmappableDocuments(id);
  }

  public downloadUnmappedDocuments(id: string): Observable<Blob> {
    return this.api.downloadUnmappedDocuments(id);
  }

  public getBookById(id: string): Observable<Book> {
    return this.api.getBookById(id);
  }

  public addPage(data: FormData): Observable<any> {
    return this.api.addPage(data);
  }

  public uploadPage(data: FormData): Observable<any> {
    return this.api.uploadPage(data);
  }

  public replacePage(data: FormData): Observable<any> {
    return this.api.replacePage(data);
  }

  public getAssetMapping(
    documentNumber: string,
    countyId: string,
    bookType?: string,
    bookNumber?: string,
    pageNumber?: string
  ): Observable<BookMapping> {
    return this.api.getAssetMapping(
      documentNumber,
      countyId,
      bookType,
      bookNumber,
      pageNumber
    );
  }

  /**
   * Pages
   */

  public getPagesByBookId(id: string): Observable<Page[]> {
    return this.api.getPagesByBookId(id);
  }

  public updatePage(page: Page): Observable<Page[]> {
    return this.api.updatePage(page);
  }

  public deletePageById(pageId: string): Observable<Page[]> {
    return this.api.deletePageById(pageId);
  }

  public rotatePages(
    pages: Page[],
    direction: 'east' | 'west' | 'south'
  ): Observable<Page[]> {
    return this.api.batchProcessPages(direction, { pages });
  }

  public mergePages(pages: Page[]): Observable<Page[]> {
    return this.api.batchProcessPages('merge', { pages });
  }

  public splitPages(pages: Page[]): Observable<Page[]> {
    return this.api.batchProcessPages('split', { pages });
  }

  public deletePages(pages: Page[]): Observable<Page[]> {
    return this.api.batchProcessPages('delete', { pages });
  }

  public assignInstrumentNumber(
    pages: Page[],
    instrumentNumber: string
  ): Observable<Page[]> {
    return this.api.batchProcessPages('assign_inst_no', {
      pages,
      instrumentNumber,
    });
  }

  public createDownload(
    userId: string,
    pageIds: string[]
  ): Observable<Download> {
    return this.api.createDownload(userId, pageIds);
  }

  public printPage(userId: string, pageIds: string[]): Observable<{}> {
    return this.api.printPage(userId, pageIds);
  }

  /**
   * Search
   */

  public search(params, countyId: string): Observable<App.List.Results> {
    return this.api.search(params, countyId);
  }
}
