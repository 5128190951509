<div class="brand">
  <img
    width="150"
    class="screen"
    src="/assets/images/vaultside-horizontal-logo.svg"
  />
  <img
    width="150"
    class="print"
    src="/assets/images/vaultside-horizontal-logo-dark.svg"
  />
</div>
