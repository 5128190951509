import { Pipe, PipeTransform } from '@angular/core';

import { durationSelectOptions } from '@app/shared/billing-profiles/billing-profiles.constants';

@Pipe({
  name: 'billingProfileDuration'
})
export class BillingProfileDurationPipe implements PipeTransform {

  transform(value: any): any {
    return durationSelectOptions.find( (option) => {
      return +option.type === +value;
    }).label;
  }

}
