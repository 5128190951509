import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Directives
 */
import { AsyncSrcDirective } from '@app/shared/directives/async-src.directive';
import { ClickStopDirective } from '@app/shared/directives/click-stop.directive';
import { DateMaskDirective } from '@app/shared/directives/date-mask.directive';
import { InactivityDirective } from '@app/shared/directives/inactivity.directive';
import { PhoneMaskDirective } from '@app/shared/directives/phone-mask.directive';
import { NgxErrorDirective } from './ngx-errors/ngx-error.directive';
import { NgxErrorsDirective } from './ngx-errors/ngx-errors.directive';

export const sharedDirectives = [
  AsyncSrcDirective,
  ClickStopDirective,
  DateMaskDirective,
  InactivityDirective,
  PhoneMaskDirective,
  NgxErrorDirective,
  NgxErrorsDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...sharedDirectives],
  exports: [...sharedDirectives],
})
export class SharedDirectivesModule {}
