import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Select } from '@ngxs/store';

import { AppState } from '@app/app.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'vault-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

  @Select(AppState.version) version$: Observable<string>;

}
