import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, catchError } from 'rxjs/operators';

import { Login, Session, Register } from '@app/shared/auth/auth.constants';
import { LoggerService } from '@app/core/services/logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(private http: HttpClient) {}

  public login(credentials: Login): Observable<Session> {
    return this.http
      .post<Session>(`${this.apiUrl}/session`, credentials)
      .pipe(catchError(this.handleError));
  }

  public register(userRegistration: Register): Observable<Session> {
    return this.http
      .post<Session>(`${this.apiUrl}/users/register`, userRegistration)
      .pipe(catchError(this.handleError));
  }

  public logout(): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/session`)
      .pipe(catchError(this.handleError));
  }

  public forgotPassword(email: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/forgot_password`, email)
      .pipe(catchError(this.handleError));
  }

  public getVersion(): Observable<string> {
    return this.http
      .get<{ version: string }>(`${this.apiUrl}/application/version`)
      .pipe(
        map(({ version }) => version),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response | any) {
    LoggerService.error(error);
    return observableThrowError(error);
  }
}
