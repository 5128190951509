import { Component, Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AppState } from '@app/app.state';

@Component({
  selector: 'vault-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  @Select(AppState.showSpinner) showSpinner$: Observable<boolean>;

  @Output() private spinnerClick: EventEmitter<null> = new EventEmitter();

  public handleClick(): void {
    this.spinnerClick.emit();
  }

}
