import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AppStateModel } from '@app/shared';
import * as Actions from '@app/app.actions';

@Injectable({
  providedIn: 'root'
})
export class CanActivateViaAuthGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree {

    const ctx = this.store.selectSnapshot<AppStateModel>( state => state.app );

    if (!!ctx.token) {

      if (ctx.user.termsAccepted) {
        return true;
      } else {
        this.store.dispatch( new Actions.WarningMessage('app.must_accept_terms') );
        return this.router.parseUrl('/terms_of_use/accept');
      }

    } else {
      return this.router.parseUrl('/login');
    }

  }

}
