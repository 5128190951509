import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { Download } from '@app/shared';
import { UsersApiService } from '@app/shared/services/users/users-api.service';

@Component({
  selector: 'vault-pdf-generated-alert',
  templateUrl: './pdf-generated-alert.component.html',
  styleUrls: ['./pdf-generated-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfGeneratedAlertComponent {
  @Input() public payload: any;

  constructor(private usersApiService: UsersApiService) {}

  public downloadPages(event: Event, download: Download) {
    event.preventDefault();
    this.usersApiService.downloadPages(download.user, download).subscribe();
  }
}
