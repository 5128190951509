/**
 * Strip null/undefined properties from an object
 */
export function removeEmptyProperties(obj: any) {
  return Object.keys(obj)
    .filter((k) => {
      return obj[k] != null;
    })
    .reduce((acc, k) => {
      acc[k] =
        typeof obj[k] === 'object' ? removeEmptyProperties(obj[k]) : obj[k];
      return acc;
    }, {});
}
