import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: any): any {

    if (!value) { return; }

    return value.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');

  }

}
