import {
  Directive,
  DoCheck,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ErrorOptions } from './ngxerrors';
import { NgxErrorsDirective } from './ngx-errors.directive';
import { toArray } from './utils';

// tslint:disable: no-bitwise
// tslint:disable: variable-name

@Directive({
  selector: '[ngxError]',
})
export class NgxErrorDirective implements OnInit, OnDestroy, DoCheck {
  @Input()
  set ngxError(value: ErrorOptions) {
    this.errorNames = toArray(value);
  }

  @Input()
  set when(value: ErrorOptions) {
    this.rules = toArray(value);
  }

  @HostBinding('hidden') public hidden = true;

  private rules: string[] = [];
  private errorNames: string[] = [];
  private subscription: Subscription;
  private _states$: Subject<string[]>;
  private states$: Observable<string[]>;

  constructor(
    @Inject(forwardRef(() => NgxErrorsDirective))
    private ngxErrors: NgxErrorsDirective
  ) {}

  ngOnInit() {
    this._states$ = new Subject<string[]>();
    this.states$ = this._states$.asObservable().pipe(distinctUntilChanged());

    const errors = this.ngxErrors.subject$.pipe(
      filter(Boolean),
      filter((obj: any) => !!~this.errorNames.indexOf(obj.errorName))
    );

    const states = this.states$.pipe(
      map((statesArr) => this.rules.every((rule) => !!~statesArr.indexOf(rule)))
    );

    this.subscription = combineLatest([states, errors]).subscribe(
      ([_states, _errors]) => {
        this.hidden = !(_states && _errors.control.hasError(_errors.errorName));
      }
    );
  }

  ngDoCheck() {
    this._states$.next(
      this.rules.filter((rule) => (this.ngxErrors.control as any)[rule])
    );
  }

  ngOnDestroy() {
    this._states$.complete();
    this.subscription.unsubscribe();
  }
}
