import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, catchError, tap, delay } from 'rxjs/operators';
import { saveAs } from 'file-saver/dist/FileSaver';
import { Store } from '@ngxs/store';

import {
  Book,
  BookMapping,
  Page,
  UnmappedAssets,
} from '@app/shared/books/books.models';
import { Download } from '@app/shared/downloads/downloads.models';
import { LoggerService } from '../logger/logger.service';
import { AppStateModel } from '@app/shared';
import { Assets } from '@app/books/assets.d';
import { StringifyLegalDescriptionPipe } from '@app/shared/pipes/stringify-legal-description/stringify-legal-description.pipe';
import { removeEmptyProperties } from '@app/shared/helpers';
import { App } from '@app/app';
import { ErrorMessage } from '@app/app.actions';
import { TranslateService } from '@ngx-translate/core';

const apiUrl = environment.apiUrl;

export type PageAction =
  | 'east'
  | 'west'
  | 'south'
  | 'merge'
  | 'split'
  | 'download'
  | 'delete'
  | 'assign_inst_no';

@Injectable({
  providedIn: 'root',
})
export class BooksApiService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private stringifyLegal: StringifyLegalDescriptionPipe,
    private translateService: TranslateService
  ) {}

  /**
   * Books
   */

  public getAssetsForCounty(
    countyId: string,
    kind: number,
    parameters
  ): Observable<App.List.Results> {
    return this.http
      .get<App.List.Results>(`${apiUrl}/counties/${countyId}/books/${kind}`, {
        params: removeEmptyProperties(parameters),
      })
      .pipe(
        map((results) => {
          return {
            ...results,
            items: results.items.map((b) => new Book(b)),
          };
        }),
        catchError((error) => {
          const message = this.translateService.instant(
            'books.get_books_error'
          );
          this.store.dispatch(new ErrorMessage(message));
          return this.handleError(error);
        })
      );
  }

  public getBookById(id: string): Observable<Book> {
    return this.http.get<Book>(`${apiUrl}/books/${id}`).pipe(
      map((b) => new Book(b)),
      catchError(this.handleError)
    );
  }

  public downloadBookInventory(countyId: string): Observable<any> {
    const ctx = this.store.selectSnapshot<AppStateModel>((state) => state.app);
    return this.http
      .get(`${apiUrl}/books/notes`, {
        responseType: 'blob',
        params: { countyId },
        headers: { Accept: 'text/csv' },
      })
      .pipe(
        tap((blob) => saveAs(blob, `${ctx.county.name} County Inventory.csv`)),
        catchError(this.handleError)
      );
  }

  public getUnmappableDocuments(
    countyId: string
  ): Observable<UnmappedAssets[]> {
    return this.http
      .get<UnmappedAssets[]>(
        `${apiUrl}/counties/${countyId}/documents/unmappable`
      )
      .pipe(catchError(this.handleError));
  }

  public getUnmappedDocuments(countyId: string): Observable<UnmappedAssets[]> {
    return this.http
      .get<UnmappedAssets[]>(
        `${apiUrl}/counties/${countyId}/documents/unmapped`
      )
      .pipe(catchError(this.handleError));
  }

  public downloadUnmappableDocuments(countyId: string): Observable<Blob> {
    const ctx = this.store.selectSnapshot<AppStateModel>((state) => state.app);
    return this.http
      .get(`${apiUrl}/counties/${countyId}/documents/unmappable`, {
        responseType: 'blob',
        params: { format: 'csv' },
        headers: { Accept: 'text/csv' },
      })
      .pipe(
        tap((blob) =>
          saveAs(blob, `${ctx.county.name} County Missing Mapping Data.csv`)
        ),
        catchError(this.handleError)
      );
  }

  public downloadUnmappedDocuments(countyId: string): Observable<Blob> {
    const ctx = this.store.selectSnapshot<AppStateModel>((state) => state.app);
    return this.http
      .get(`${apiUrl}/counties/${countyId}/documents/unmapped`, {
        responseType: 'blob',
        params: { format: 'csv' },
        headers: { Accept: 'text/csv' },
      })
      .pipe(
        tap((blob) =>
          saveAs(blob, `${ctx.county.name} County Unmapped Documents.csv`)
        ),
        catchError(this.handleError)
      );
  }

  public updateBook(book: Book): Observable<Book> {
    return this.http.put<Book>(`${apiUrl}/books/${book.id}`, book).pipe(
      map((b) => new Book(b)),
      catchError(this.handleError)
    );
  }

  public deleteBookById(id: string): Observable<Book> {
    return this.http.delete<Book>(`${apiUrl}/books/${id}`).pipe(
      map((b) => new Book(b)),
      catchError(this.handleError)
    );
  }

  public addPage(data: FormData): Observable<any> {
    const book = data.get('book');
    return this.http
      .post(`${apiUrl}/books/${book}/pages`, data)
      .pipe(catchError(this.handleError));
  }

  public uploadPage(data: FormData): Observable<any> {
    const book = data.get('book');
    return this.http
      .post(`${apiUrl}/books/${book}/pages`, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(this.handleError));
  }

  public replacePage(data: FormData): Observable<any> {
    const id = data.get('id');
    return this.http
      .put(`${apiUrl}/pages/${id}`, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(this.handleError));
  }

  public getAssetMapping(
    documentNumber: string,
    countyId: string,
    bookType?: string,
    bookNumber?: string,
    pageNumber?: string
  ): Observable<BookMapping> {
    return this.http
      .get<BookMapping>(`${apiUrl}/books/mapping`, {
        params: { documentNumber, countyId, bookType, bookNumber, pageNumber },
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * Pages
   */

  public getPagesByBookId(bookId: string): Observable<Page[]> {
    return this.http.get<Page[]>(`${apiUrl}/pages?book=${bookId}`).pipe(
      map((pages) => pages.map((p) => new Page(p))),
      catchError(this.handleError)
    );
  }

  public deletePageById(pageId: string): Observable<Page[]> {
    return this.http.delete<Page[]>(`${apiUrl}/pages/${pageId}`).pipe(
      map((pages) => pages.map((p) => new Page(p))),
      catchError(this.handleError)
    );
  }

  public updatePage(page: Page): Observable<Page[]> {
    return this.http.put<Page[]>(`${apiUrl}/pages/${page.id}`, page).pipe(
      map((pages) => pages.map((p) => new Page(p))),
      catchError(this.handleError)
    );
  }

  public batchProcessPages(
    action: PageAction,
    payload: any
  ): Observable<Page[]> {
    return this.http
      .post<Page[]>(`${apiUrl}/pages?action=${action}`, payload)
      .pipe(
        map((pages) => pages.map((p) => new Page(p))),
        catchError(this.handleError)
      );
  }

  public regenerateDownloadById(
    userId: string,
    downloadId: string
  ): Observable<Download> {
    return this.http
      .put<Download>(
        `${apiUrl}/users/${userId}/pagedownload/${downloadId}`,
        null
      )
      .pipe(catchError(this.handleError));
  }

  public createDownload(
    userId: string,
    pageIds: string[]
  ): Observable<Download> {
    return this.http
      .post<Download>(`${apiUrl}/users/${userId}/pagedownload`, pageIds)
      .pipe(catchError(this.handleError));
  }

  public printPage(userId: string, pageIds: string[]): Observable<{}> {
    return this.http
      .post(`${apiUrl}/users/${userId}/pageprint`, pageIds)
      .pipe(catchError(this.handleError));
  }

  /**
   * Search
   */

  public search(parameters, countyId: string): Observable<App.List.Results> {
    return this.http
      .post<App.List.Results>(
        `${apiUrl}/counties/${countyId}/search`,
        removeEmptyProperties(parameters)
      )
      .pipe(
        map((results) => {
          return {
            ...results,
            items: results.items.map((result: Assets.Search.Result) => ({
              ...result,
              $grantors: this.concatGrantorsGrantees(result.grantors),
              $grantees: this.concatGrantorsGrantees(result.grantees),
              $legalDescriptions: this.concatLegalDescriptions(
                result.legalDescriptions
              ),
            })),
          };
        }),
        catchError(this.handleError)
      );
  }

  private concatGrantorsGrantees(
    items: Assets.Search.GrantorGrantee[]
  ): string {
    return items.map((item) => item.name).join(' : ');
  }

  private concatLegalDescriptions(
    items: Assets.Search.LegalDescription[]
  ): string {
    return items.map((item) => this.stringifyLegal.transform(item)).join(' : ');
  }

  private handleError(error: Response | any) {
    LoggerService.error(error);
    return observableThrowError(error);
  }
}
