import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AppStateModel } from '@app/shared';

@Injectable({
  providedIn: 'root'
})
export class RedirectToStartPageGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree {

    const state = this.store.selectSnapshot<AppStateModel>( st => st.app );

    if (!!state.token) {
      return this.router.parseUrl(state.user.$startUrl);
    } else {
      return true;
    }

  }

}
