import { Pipe, PipeTransform } from '@angular/core';
import { Assets } from '@app/books/assets.d';

@Pipe({
  name: 'stringifyLegalDescription',
})
export class StringifyLegalDescriptionPipe implements PipeTransform {
  transform({
    township,
    range,
    section,
    description,
    city,
    subdivision,
    block,
    lot,
  }: Assets.Search.LegalDescription): string {
    if (township) {
      return `${this.formatTownship(township)} ${this.formatRange(
        range
      )} S${section} ${description}`;
    }
    let desc = '';
    if (city) {
      desc += city;
    }
    if (subdivision) {
      desc += ` ${subdivision}`;
    }
    if (description) {
      desc += ` ${description}`;
    }
    if (block) {
      desc += ` Block ${block}`;
    }
    if (lot) {
      desc += ` Lot ${lot}`;
    }
    return desc;
  }

  private formatTownship(township: string): string {
    const direction = township.match(/N|S$/) ? '' : 'N';
    return `T${township + direction}`;
  }

  private formatRange(range: string): string {
    const direction = range.match(/E|W$/) ? '' : 'W';
    return `R${range + direction}`;
  }
}
