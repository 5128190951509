import {
  Component,
  OnInit,
  HostBinding,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'vault-text-search',
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextSearchComponent implements OnInit {
  @HostBinding('class') componentClass = 'vault-text-search';

  @Input() public placeholder: string;
  @Input() public debounce = 300;
  @Input() public isLoading: boolean;

  @Output() public search: EventEmitter<string> = new EventEmitter();

  public control = new UntypedFormControl();
  private emit = true;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.control.valueChanges
      .pipe(debounceTime(this.debounce))
      .pipe(distinctUntilChanged())
      .subscribe((term) => {
        this.cdr.detectChanges();
        if (this.emit) {
          this.search.emit(term);
        } else {
          this.emit = true;
        }
      });
  }

  public get hasValue() {
    return !!this.control.value;
  }

  public clear(emit: boolean = true) {
    this.emit = emit;
    this.control.setValue('');
  }
}
