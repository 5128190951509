<div class="modal-header">
  <h4 class="modal-title" [innerHtml]="title"></h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="dismiss()"
  ></button>
</div>

<div class="modal-body">
  <ng-content></ng-content>
</div>

<ng-content select=".modal-footer"></ng-content>
