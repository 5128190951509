import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as Actions from '@app/app.actions';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (+error.status) {
          case 401: return this.handle401(error);
          case 402: return this.handle402(error);
          case 403: return this.handle403(error);
          case 404: return this.handle404(error);
          case 500: return this.handle500(error);
          default: return throwError(error);
        }
      })
    );
  }

  private handle401(error: HttpErrorResponse) {
    if (error.error !== 'app.401_password_reset_token_invalid') {
      this.store.dispatch(new Actions.Logout());
    }
    return throwError(error);
  }

  private handle402(error: HttpErrorResponse) {
    this.store.dispatch([new Actions.SubscriptionExpiration()]);
    return throwError(error);
  }

  private handle403(error: HttpErrorResponse) {
    this.store.dispatch(new Actions.Logout());
    return throwError(error);
  }

  private handle404(error: HttpErrorResponse) {
    this.store.dispatch(new Actions.ErrorMessage('app.404_error'));
    return throwError(error);
  }

  private handle500(error: HttpErrorResponse) {
    this.store.dispatch(new Actions.ErrorMessage('app.500_error'));
    return throwError(error);
  }
}
