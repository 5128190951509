<vault-page-header>
  <vault-logo></vault-logo>
  <vault-navigation [user]="ctx.user"></vault-navigation>
  <div class="secondary">
    <vault-user-menu></vault-user-menu>
  </div>
</vault-page-header>

<ng-content select="vault-stage"></ng-content>

<ng-content select="vault-page-tabs"></ng-content>

<ng-content select="vault-page-toolbar"></ng-content>

<div
  class="vault-flex-content"
  [ngClass]="sidebarClass">

  <ng-content select="vault-sidebar"></ng-content>

  <div class="vault-content auto-scroll">
    <div [ngClass]="containerClass">
      <ng-content></ng-content>
    </div>
  </div>

</div>

<ng-content select="vault-actions-footer"></ng-content>
