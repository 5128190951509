export enum subscriptionDuration {
  hour = 1,
  day = 2,
  week = 3,
  month = 4,
  year = 5,
}

export const durationSelectOptions = [
  {
    type: subscriptionDuration.hour,
    label: 'billing_profiles.duration_hour'
  }, {
    type: subscriptionDuration.day,
    label: 'billing_profiles.duration_day'
  }, {
    type: subscriptionDuration.week,
    label: 'billing_profiles.duration_week'
  }, {
    type: subscriptionDuration.month,
    label: 'billing_profiles.duration_month'
  }, {
    type: subscriptionDuration.year,
    label: 'billing_profiles.duration_year'
  }
];

export enum subscriptionPackage {
  dayPass = 'dayPass',
  examiner = 'examiner',
  abstractor = 'abstractor'
}

export const packageSelectOptions = [
  {
    type: subscriptionPackage.dayPass,
    label: 'billing_profiles.day_pass',
  }, {
    type: subscriptionPackage.examiner,
    label: 'billing_profiles.examiner_series',
  }, {
    type: subscriptionPackage.abstractor,
    label: 'billing_profiles.abstractor',
  }
];

export interface StripeToken {
  id?: string;
  profileId?: string;
}
