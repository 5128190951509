import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppStateModel } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionRequestInterceptorService implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const {
      county,
      countySubscriptions,
    } = this.store.selectSnapshot<AppStateModel>((state) => {
      return state.app;
    });

    if (county?.id && countySubscriptions?.length) {
      const token = countySubscriptions.find(
        (subscription) => subscription.countyId === county.id
      )?.subscriptionToken;
      if (token) {
        request = request.clone({
          headers: request.headers.set('Subscription', token),
        });
      }
    }

    return next.handle(request);
  }
}
