<div class="vault-split-view">
  <div class="primary">
    <div *ngIf="backLabel" class="stage-back-link" (click)="goBack()">
      <i class="fas fa-caret-left"></i>
      {{ backLabel }}
    </div>
    <h2 class="h2 stage-title" [innerHtml]="title"></h2>
  </div>

  <div class="secondary">
    <ng-content select=".actions"></ng-content>
  </div>
</div>

<ng-content></ng-content>
