import { Pipe, PipeTransform } from '@angular/core';

import { UserRoleOptions, UserRoles } from '@app/shared/users/users.constants';

@Pipe({
  name: 'userRoleLabel'
})
export class UserRoleLabelPipe implements PipeTransform {

  transform(role: UserRoles): string {
    return UserRoleOptions.find( (option) => {
      return option.type === role;
    }).label;
  }

}
