import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

/**
 * To use in template, add [@collapseExpand] trigger
 * to an html element controlled by an *ngIf or *ngFor statement
 * Collapse animation is triggered before removal of element
 * and expanded when element added to the template
 */
export const collapseExpand = trigger('collapseExpand', [
  state('void', style({ height: '0px', opacity: '0' })),
  state('*', style({ height: '*', opacity: '1' })),
  transition('void <=> *', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);

/**
 * To use in template, add [@fadeIn] trigger
 * to an html element controlled by an *ngIf or *ngFor statement
 * Fade in animation is triggered when element added to the template
 */
export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms ease-in', style({ opacity: 1 })),
  ]),
]);

/**
 * To use in template, add [@fadeInAndOut] trigger
 * to an html element controlled by an *ngIf or *ngFor statement
 * Fade out animation is triggered before removal of element
 * and fade in when element added to the template
 */
export const fadeInAndOut = trigger('fadeInAndOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms ease-in', style({ opacity: 1 })),
  ]),
  transition(':leave', animate('0.2s ease-out', style({ opacity: 0 }))),
]);
