import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
} from '@angular/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'vault-list-date-range',
  templateUrl: './list-date-range.component.html',
  styleUrls: ['./list-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListDateRangeComponent {
  private _start: string;
  private _end: string;

  @Input() public format: string = 'MMM d, y';

  @Input() public set start(date: string) {
    this._start = this.formatDate(date);
  }
  public get start(): string {
    return this._start;
  }

  @Input() public set end(date: string) {
    this._end = this.formatDate(date);
  }
  public get end(): string {
    return this._end;
  }

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public get hasDate(): boolean {
    return !!(this.start || this.end);
  }

  private formatDate(date: string): string {
    if (!date) {
      return undefined;
    }
    return formatDate(date, this.format, this.locale);
  }
}
