import { Component, HostBinding, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngxs/store';

import { AppStateModel } from '@app/shared/app/app.models';

@Component({
  selector: 'vault-layout-default',
  templateUrl: './layout-default.component.html',
  styleUrls: ['./layout-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutDefaultComponent implements OnInit {

  @HostBinding('class') componentClass = 'vault-flex-layout';

  @Input() sidebarSize = null;
  @Input() noContainerPadding = null;

  public ctx: AppStateModel;
  public sidebarClass = null;
  public containerClass = null;

  constructor(
    private store: Store
  ) {}

  ngOnInit() {
    this.ctx = this.store.selectSnapshot<AppStateModel>( state => state.app );
    if (this.sidebarSize) {
      this.sidebarClass = `sidebar-${this.sidebarSize}`;
    }
    if (!this.noContainerPadding) {
      this.containerClass = 'container-fluid';
    }
  }

}
