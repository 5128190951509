import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Components
 */
import { ActionsFooterComponent } from '@app/shared/components/actions-footer/actions-footer.component';
import { AlertComponent } from './alert/alert.component';
import { DebugDataComponent } from './debug-data/debug-data.component';
import { DrawerComponent } from './drawer/drawer.component';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { FileUploadComponent } from '@app/shared/components/file-upload/file-upload.component';
import { FilterShortcutsComponent } from './filter-shortcuts/filter-shortcuts.component';
import { FilterTabsComponent } from './filter-tabs/filter-tabs.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutDefaultComponent } from '@app/shared/components/layouts/layout-default/layout-default.component';
import { LayoutSimpleFormComponent } from './layouts/layout-simple-form/layout-simple-form.component';
import { LayoutTrowserComponent } from '@app/shared/components/layouts/layout-trowser/layout-trowser.component';
import { LayoutUnauthorizedComponent } from '@app/shared/components/layouts/layout-unauthorized/layout-unauthorized.component';
import { ListPickerComponent } from '@app/shared/components/list-picker/list-picker.component';
import { ListViewComponent } from '@app/shared/components/list-view/list-view.component';
import { LogoComponent } from '@app/shared/components/logo/logo.component';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { NavigationComponent } from '@app/shared/components/navigation/navigation.component';
import { NoSearchResultsComponent } from './no-search-results/no-search-results.component';
import { PageHeaderComponent } from '@app/shared/components/page-header/page-header.component';
import { PageTabsComponent } from './page-tabs/page-tabs.component';
import { PageToolbarComponent } from '@app/shared/components/page-toolbar/page-toolbar.component';
import { SidebarComponent } from '@app/shared/components/sidebar/sidebar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StageComponent } from '@app/shared/components/stage/stage.component';
import { TableToolbarComponent } from './table-toolbar/table-toolbar.component';
import { TextSearchComponent } from '@app/shared/components/text-search/text-search.component';
import { ToggleSwitchComponent } from '@app/shared/components/toggle-switch/toggle-switch.component';
import { UserMenuComponent } from '@app/shared/components/user-menu/user-menu.component';
import { PdfGeneratedAlertComponent } from './pdf-generated-alert/pdf-generated-alert.component';
import { TruncateComponent } from './truncate/truncate.component';
import { ListDateRangeComponent } from './list-date-range/list-date-range.component';
import { LocalSpinnerComponent } from './local-spinner/local-spinner.component';

export const sharedComponents = [
  ActionsFooterComponent,
  AlertComponent,
  DebugDataComponent,
  DrawerComponent,
  DropdownFilterComponent,
  FilterShortcutsComponent,
  FilterTabsComponent,
  FileUploadComponent,
  FooterComponent,
  LayoutDefaultComponent,
  LayoutSimpleFormComponent,
  LayoutTrowserComponent,
  LayoutUnauthorizedComponent,
  ListPickerComponent,
  ListViewComponent,
  LogoComponent,
  ModalComponent,
  NavigationComponent,
  NoSearchResultsComponent,
  PageHeaderComponent,
  PageTabsComponent,
  PageToolbarComponent,
  SidebarComponent,
  SpinnerComponent,
  StageComponent,
  TableToolbarComponent,
  TextSearchComponent,
  ToggleSwitchComponent,
  TruncateComponent,
  UserMenuComponent,
  PdfGeneratedAlertComponent,
  ListDateRangeComponent,
  LocalSpinnerComponent,
];

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [...sharedComponents],
  exports: [...sharedComponents],
})
export class SharedComponentsModule {}
