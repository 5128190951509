<div class="row gx-2 align-items-center">
  <div class="col-auto" *ngFor="let filter of filters">
    <button
      type="button"
      class="filter-tab btn btn-light border"
      (click)="onRemove(filter)"
    >
      {{ filter.label | translate }} <i class="fas fa-times fa-fw"></i>
    </button>
  </div>
</div>
