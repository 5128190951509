import { Pipe, PipeTransform } from '@angular/core';
import { FilterDefinition } from '@app/shared/app/app.constants';

@Pipe({
  name: 'applyFilters'
})
export class ApplyFiltersPipe implements PipeTransform {

  transform(items: Array<any>, filters: FilterDefinition[]) {

    if (!filters) { return items; }

    if (items) {

      return items.filter( (item) => {
        return filters.every( filter => item[filter.param] === filter.value );
      });

    }

    return;

  }

}
