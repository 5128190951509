import { subscriptionDuration } from '@app/shared/billing-profiles/billing-profiles.constants';

export class BillingProfile {

  public id?: string = null;
  public county: string;
  public name: string;
  public public: boolean;
  public duration: subscriptionDuration;
  public downloadLimit: number;
  public downloadIndexLimit: number;
  public signupFee: number;
  public downloadPrice: number;
  public pageViewPrice: number;
  public bookViewPrice: number;
  public pdfViewPrice: number;

  public freePgViews: number;
  public freeBookViews: number;
  public freePdfViews: number;
  public freePgDownloads: number;

  public indexPageViewPrice: number;
  public indexViewPrice: number;
  public indexPdfViewPrice: number;
  public indexDownloadPrice: number;

  public freeIndexPgViews: number;
  public freeIndexViews: number;
  public freeIndexPdfViews: number;
  public freeIndexPgDownloads: number;

  constructor(profile?: BillingProfile) {
    if ( profile ) {
      Object.assign(this, profile);
    }
  }
}
