import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LoggerService } from '@app/core/services/logger/logger.service';
import { AppStateModel } from '@app/shared';
import * as Actions from '@app/app.actions';

@Injectable({
  providedIn: 'root'
})
export class RequireCountySelectedGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree {

    const ctx = this.store.selectSnapshot<AppStateModel>( state => state.app );

    /**
     * Send to counties list if no county selected
     */
    if (!ctx.county || !ctx.county.id) {
      LoggerService.warn('County not selected, redirecting to counties list.').track();
      this.store.dispatch( new Actions.InfoMessage('app.please_select_county') );
      return this.router.parseUrl('/counties');
    } else {
      return true;
    }

  }

}
