import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppStateModel } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class RedirectToCountyTabGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const ctx = this.store.selectSnapshot<AppStateModel>((state) => state.app);
    const baseUrl = `/counties/${ctx.county.id}/assets`;

    // Send admins/curators to document search
    if (!ctx.user.$isViewOnly) {
      return this.router.parseUrl(`${baseUrl}/search`);
    }

    // Send view only users to first enabled module
    if (ctx.county.hasDigitalIndex) {
      return this.router.parseUrl(`${baseUrl}/search`);
    }
    if (ctx.county.hasBooks) {
      return this.router.parseUrl(`${baseUrl}/books`);
    }
    if (ctx.county.hasIndexes) {
      return this.router.parseUrl(`${baseUrl}/indices`);
    }
    if (ctx.county.hasPlatsMaps) {
      return this.router.parseUrl(`${baseUrl}/plats_maps`);
    }
  }
}
