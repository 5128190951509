import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'vault-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() type: 'info' | 'success' | 'warning' | 'danger';

  public icon: string;
  public style: string;

  public ngOnInit(): void {
    this.icon = alertIcons[this.type];
    this.style = alertTypes[this.type];
  }

}

const alertIcons = {
  info: 'info-circle',
  success: 'check-circle',
  warning: 'exclamation-circle',
  error: 'times-circle'
};

const alertTypes = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'danger'
};
