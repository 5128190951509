import { Component, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngxs/store';

import * as Actions from '@app/app.actions';

@Component({
  selector: 'vault-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {

  @HostBinding('class') componentClass = 'vault-user-menu';

  constructor(
    private store: Store,
  ) {}

  public logout() {
    this.store.dispatch( new Actions.Logout() );
  }
}
