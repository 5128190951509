import { Directive, OnInit, OnDestroy, Output, Renderer2, ElementRef, EventEmitter } from '@angular/core';

@Directive({
  selector: '[vaultClickStop]'
})
export class ClickStopDirective implements OnInit {

  @Output() stopPropEvent = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
  ) { }

  ngOnInit() {
    this.renderer.listen(this.element.nativeElement, 'click', event => {
      event.stopPropagation();
      event.preventDefault();
      this.stopPropEvent.emit(event);
    });
  }

}
