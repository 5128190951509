import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@env/environment';

import { UserRoles } from '@app/shared/users/users.constants';
import { LoggerService } from './logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private role: UserRoles = null;

  constructor(
    private router: Router,
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.canUseGa) { return; }
        try {
          if (this.role) {
            (window as any).ga('set', 'dimension1', this.role);
          }
          (window as any).ga('set', 'page', event.url);
          (window as any).ga('send', 'pageview');
        } catch (error) {
          LoggerService.warn(error);
        }
      }
    });

  }

  public setRole(role: UserRoles): void {
    this.role =  role;
  }

  public sendEvent(event: AnalyticsEvent): void {
    if (!this.canUseGa) { return; }
    try {
      (window as any).ga('send', 'event', event);
    } catch (error) {
      LoggerService.warn(error);
    }
  }

  private get canUseGa(): boolean {
    return environment.googleAnalytics && typeof (window as any).ga !== 'undefined';
  }

}

export interface AnalyticsEvent {
  eventCategory: string;
  eventActions: string;
  eventLabel?: string;
  eventValue?: number;
}
