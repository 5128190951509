<div class="vault-actions-footer">

  <div class="vault-split-view">

    <div class="primary">
      <ng-content></ng-content>
    </div>

    <ng-content select=".secondary"></ng-content>

  </div>

</div>
