import { InjectionToken } from '@angular/core';
import stringify from 'json-stringify-safe';

export const STRINGIFY = new InjectionToken<Stringify>('STRINGIFY');
export const StringifyProvider = {
  provide: STRINGIFY,
  useValue: stringify,
};

export type Stringify = (
  obj: any,
  serializer?: stringify.EntryProcessor | null,
  indent?: string | number | null,
  decycler?: stringify.EntryProcessor
) => string;
