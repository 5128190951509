import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vault-truncate',
  templateUrl: './truncate.component.html',
  styleUrls: ['./truncate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TruncateComponent {
  @Input() public text: string;
  @Input() public tooltipPlacement: string;

  constructor() {}
}
