import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FilterDefinition } from '@app/shared/app/app.constants';

@Component({
  selector: 'vault-filter-tabs',
  templateUrl: './filter-tabs.component.html',
  styleUrls: ['./filter-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTabsComponent {
  @Input() filters: FilterDefinition[];

  @Output() remove: EventEmitter<FilterDefinition> = new EventEmitter();
  @Output() clear: EventEmitter<void> = new EventEmitter();

  public onRemove(filter: FilterDefinition): void {
    this.remove.emit(filter);
  }

  public onClear(): void {
    this.clear.emit();
  }
}
