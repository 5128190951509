<span #toggleSwitch
  class="vault-toggle-switch"
  (click)="toggle()"
  [ngClass]="{
    disabled : disabled,
    on : isOn,
    off : !isOn
  }">
    <span *ngIf="isOn" class="vault-toggle-label vault-toggle-label-on">{{trueLabel}}</span>
    <span class="vault-toggle-thumb"></span>
    <span *ngIf="!isOn" class="vault-toggle-label vault-toggle-label-off">{{falseLabel}}</span>
  </span>
