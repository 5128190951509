<span *ngIf="hasDate; else noDates">
  <span *ngIf="start; else dateMissing" [textContent]="start"></span>
  <span class="text-muted"> - </span>
  <span *ngIf="end; else dateMissing" [textContent]="end"></span>
</span>

<ng-template #noDates>
  <span class="text-muted">
    <i class="fa fa-times text-muted"></i>
  </span>
</ng-template>

<ng-template #dateMissing>
  <i class="fa fa-question fa-xs"></i>
</ng-template>
