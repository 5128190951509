export type UserRole = UserRoles.admin | UserRoles.curator | UserRoles.viewOnly;

export enum UserRoles {
  admin = 'admin',
  curator = 'curator',
  viewOnly = 'viewOnly',
}

export const UserRoleOptions = [
  {
    type: UserRoles.admin,
    label: 'app.admin',
  },
  {
    type: UserRoles.curator,
    label: 'app.curator',
  },
  {
    type: UserRoles.viewOnly,
    label: 'app.view_only',
  },
];

export interface ResetPassword {
  validationToken: string;
  password: string;
  confirmPassword: string;
}

export interface ChangePassword {
  id: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export enum AlertType {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  success = 'success',
  light = 'light',
  dark = 'dark',
}

export const UsersFilters = {
  1: {
    id: 1,
    param: 'locked',
    value: true,
    label: 'users.locked_users',
    shortcut: true,
  },
  2: {
    id: 2,
    param: 'lastLogin',
    value: undefined,
    label: 'users.never_logged_in',
    shortcut: true,
  },
  3: {
    id: 3,
    param: '$isAdmin',
    value: true,
    label: 'app.admin',
    dropdown: true,
  },
  4: {
    id: 4,
    param: '$isCurator',
    value: true,
    label: 'app.curator',
    dropdown: true,
  },
  5: {
    id: 5,
    param: '$isViewOnly',
    value: true,
    label: 'app.view_only',
    dropdown: true,
  },
};
