import {
  Login as LoginCredentials,
  Session,
} from '@app/shared/auth/auth.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from './shared/users/users.models';
import { State } from './shared/states/states.models';
import { County } from './shared/counties/counties.models';
import { StripeToken } from './shared/billing-profiles/billing-profiles.constants';
import { UserRoles } from './shared/users/users.constants';
import { ChangePassword as IChangePassword } from '@app/shared/users/users.constants';
import { Register as RegisterCredentials } from '@app/shared/auth/auth.constants';
import { ResetPassword as ResetPasswordCredentials } from '@app/shared/users/users.constants';

/**
 * Reset
 */

export class ResetState {
  static readonly type = '[APP] ResetState';
}

/**
 * Set version
 */

export class SetVersion {
  static readonly type = '[APP] SetVersion';
}

/**
 * Spinner
 */

export class ShowSpinner {
  static readonly type = '[APP] ShowSpinner';
}

export class HideSpinner {
  static readonly type = '[APP] HideSpinner';
}

/**
 * Mask
 */

export class ShowMask {
  static readonly type = '[APP] ShowMask';
}

export class HideMask {
  static readonly type = '[APP] HideMask';
}

/**
 * Login
 */

export class Login {
  static readonly type = '[APP] Login';
  constructor(public payload: LoginCredentials) {}
}

export class LoginSuccess {
  static readonly type = '[APP] LoginSuccess';
  constructor(public payload: Session) {}
}

export class LoginError {
  static readonly type = '[APP] LoginError';
  constructor(public payload: HttpErrorResponse) {}
}

/**
 * Logout
 */

export class Logout {
  static readonly type = '[APP] Logout';
}

export class LogoutSuccess {
  static readonly type = '[APP] LogoutSuccess';
}

export class LogoutError {
  static readonly type = '[APP] LogoutError';
}

/**
 * Register
 */

export class Register {
  static readonly type = '[APP] Register';
  constructor(public payload: RegisterCredentials) {}
}

export class RegisterSuccess {
  static readonly type = '[APP] RegisterSuccess';
}

export class RegisterError {
  static readonly type = '[APP] RegisterError';
  constructor(public payload: HttpErrorResponse) {}
}

/**
 * Reset password
 */

export class ResetPassword {
  static readonly type = '[APP] ResetPassword';
  constructor(public payload: ResetPasswordCredentials) {}
}

/**
 * Forgot password
 */

export class ForgotPassword {
  static readonly type = '[APP] ForgotPassword';
  constructor(public payload: any) {}
}

export class ForgotPasswordSuccess {
  static readonly type = '[APP] ForgotPasswordSuccess';
}

export class ForgotPasswordError {
  static readonly type = '[APP] ForgotPasswordError';
}

/**
 * Current User
 */
export class SetCurrentUser {
  static readonly type = '[APP] SetCurrentUser';
  constructor(public payload: User) {}
}

export class PatchCurrentUser {
  static readonly type = '[APP] PatchCurrentUser';
  constructor(public payload: Partial<User>) {}
}

export class ClearCurrentUser {
  static readonly type = '[APP] ClearCurrentUser';
}

export class AddSubscriptionToSession {
  static readonly type = '[APP] AddSubscriptionToSession';
  constructor(
    public payload: {
      countyId: string;
      paymentToken?: string;
      subscriptionToken: string;
    }
  ) {}
}

export class AcceptTermsOfService {
  static readonly type = '[APP] AcceptTermsOfService';
}

export class ChangePassword {
  static readonly type = '[APP] ChangePassword';
  constructor(public payload: IChangePassword) {}
}

export class ChangeStripeToken {
  static readonly type = '[APP] ChangeStripeToken';
  constructor(public payload: Partial<StripeToken>) {}
}

export class UpdateAccount {
  static readonly type = '[APP] UpdateAccount';
  constructor(public payload: Partial<User>) {}
}

/**
 * Current State
 */
export class SetCurrentState {
  static readonly type = '[APP] SetCurrentState';
  constructor(public payload: State) {}
}

export class ClearCurrentState {
  static readonly type = '[APP] ClearCurrentState';
}

/**
 * Current County
 */
export class SetCurrentCounty {
  static readonly type = '[APP] SetCurrentCounty';
  constructor(public payload: County) {}
}

export class ClearCurrentCounty {
  static readonly type = '[APP] ClearCurrentCounty';
}

/**
 * Messages
 */

export class SuccessMessage {
  static readonly type = '[APP] SuccessMessage';
  constructor(public translateKey: string, public params = {}) {}
}

export class InfoMessage {
  static readonly type = '[APP] InfoMessage';
  constructor(public translateKey: string, public params = {}) {}
}

export class ErrorMessage {
  static readonly type = '[APP] ErrorMessage';
  constructor(public translateKey: string, public params = {}) {}
}

export class WarningMessage {
  static readonly type = '[APP] WarningMessage';
  constructor(public translateKey: string, public params = {}) {}
}

/**
 * Websocket actions
 */

export class UserLocked {
  static readonly type = '[APP] UserLocked';
  constructor(public payload: User) {}
}

export class DuplicateLogin {
  static readonly type = '[APP] DuplicateLogin';
  constructor(public payload: string[]) {}
}

export class SubscriptionExpiration {
  static readonly type = '[APP] SubscriptionExpiration';
}

export class DisconnectUsers {
  static readonly type = '[APP] DisconnectUsers';
}

export class LogOutUsersByType {
  static readonly type = '[APP] LogOutUsersByType';
  constructor(public payload: UserRoles[]) {}
}
