import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  catchError,
  tap,
  finalize,
  concatMap,
  map,
  first,
} from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import {
  ConnectWebSocket,
  DisconnectWebSocket,
  SendWebSocketMessage,
} from '@ngxs/websocket-plugin';
import { NotifierService } from 'angular-notifier-updated';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';

import { LoggerService, GoogleAnalyticsService } from '@app/core';
import { AuthApiService } from '@app/shared/services/auth/auth-api.service';
import { UsersApiService } from '@app/shared/services/users/users-api.service';
import { AppStateModel } from '@app/shared/app/app.models';
import { initialAppState } from '@app/shared/app/app.constants';
import { User } from '@app/shared/users/users.models';
import { UserRoles } from '@app/shared/users/users.constants';
import * as Actions from '@app/app.actions';
import { CountyService } from './shared/services/counties/county.service';

@State<AppStateModel>({
  name: 'app',
  defaults: initialAppState,
})
@Injectable()
export class AppState {
  @Selector()
  static user(state: AppStateModel) {
    return state.user;
  }

  @Selector()
  static token(state: AppStateModel) {
    return state.token;
  }

  @Selector()
  static state(state: AppStateModel) {
    return state.state;
  }

  @Selector()
  static county(state: AppStateModel) {
    return state.county;
  }

  @Selector()
  static counties(state: AppStateModel) {
    return state.counties;
  }

  @Selector()
  static showSpinner(state: AppStateModel) {
    return state.showSpinner;
  }

  @Selector()
  static showMask(state: AppStateModel) {
    return state.showMask;
  }

  @Selector()
  static version(state: AppStateModel) {
    return state.version;
  }

  constructor(
    private usersApiService: UsersApiService,
    private authApi: AuthApiService,
    private translate: TranslateService,
    private notifier: NotifierService,
    private analytics: GoogleAnalyticsService,
    private countyService: CountyService
  ) {}

  /**
   * Reset app state
   */
  @Action(Actions.ResetState)
  public reset(ctx: StateContext<AppStateModel>) {
    ctx.setState(initialAppState);
  }

  /**
   * Set Version
   */
  @Action(Actions.SetVersion)
  public onSetVersion(ctx: StateContext<AppStateModel>) {
    return this.authApi
      .getVersion()
      .pipe(map((version) => ctx.patchState({ version })));
  }

  /**
   * Spinner
   */
  @Action(Actions.ShowSpinner)
  public onShowSpinner(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ showSpinner: true });
  }

  @Action(Actions.HideSpinner)
  public onHideSpinner(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ showSpinner: false });
  }

  /**
   * Mask
   */
  @Action(Actions.ShowMask)
  public onShowMask(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ showMask: true });
  }

  @Action(Actions.HideMask)
  public onHideMask(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ showMask: false });
  }

  /**
   * Login
   */
  @Action(Actions.Login)
  public onLogin(ctx: StateContext<AppStateModel>, { payload }: Actions.Login) {
    return this.authApi.login(payload).pipe(
      map((session) => {
        // Default to WY until we add more states
        const state = {
          enabled: true,
          name: 'Wyoming',
          abbr: 'WY',
        };
        const county = this.countyService.getCountyModel(session.user.county);
        const user = {
          ...session.user,
          county,
        };
        const _session = Object.assign(session, {
          user: new User(user),
          county,
          state,
        });
        ctx.patchState(_session);
        return _session;
      }),
      map((session) => ctx.dispatch(new Actions.LoginSuccess(session))),
      catchError((error) => ctx.dispatch(new Actions.LoginError(error)))
    );
  }

  @Action(Actions.LoginSuccess)
  public onLoginSuccess(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.LoginSuccess
  ) {
    {
      const scope = Sentry.getCurrentScope();
      scope.setTag('version', payload.version);
      scope.setTag('session', payload.sessionId);
      scope.setUser({
        name: payload.user.name,
        id: payload.user.id,
        email: payload.user.email,
        organization: payload.user.organization,
        role: payload.user.role,
        'Courthouse User': !!payload.user.countyId,
        'Stripe Token': payload.user.paymentToken,
      });
    }
    this.analytics.sendEvent({
      eventCategory: 'Logins',
      eventActions: 'Success',
    });
    LoggerService.info(payload);
    this.analytics.setRole(payload.user.role);
    ctx.dispatch(new ConnectWebSocket());
    ctx.dispatch(new Navigate([payload.user.$startUrl]));
  }

  @Action(Actions.LoginError)
  public onLoginError(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.LoginError
  ) {
    this.analytics.sendEvent({
      eventCategory: 'Logins',
      eventActions: 'Failure',
      eventLabel: `${payload.statusText} - ${payload.message}`,
    });
    ctx.dispatch(new Actions.ErrorMessage('app.bad_credentials'));
  }

  /**
   * Logout
   */
  @Action(Actions.Logout)
  public onLogout(ctx: StateContext<AppStateModel>) {
    return this.authApi.logout().pipe(
      tap(() => ctx.patchState({ token: null })),
      concatMap(() => ctx.dispatch(new Actions.LogoutSuccess())),
      catchError(() => ctx.dispatch(new Actions.LogoutError()))
    );
  }

  @Action(Actions.LogoutSuccess)
  public onLogoutSuccess(ctx: StateContext<AppStateModel>) {
    ctx.dispatch(new DisconnectWebSocket());
    ctx.dispatch(new Navigate([`/login`]));
  }

  @Action(Actions.LogoutError)
  public onLogoutError(ctx: StateContext<AppStateModel>) {
    ctx.dispatch(new Actions.ErrorMessage('app.logout_error'));
  }

  /**
   * Register
   */
  @Action(Actions.Register)
  public onRegister(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.Register
  ) {
    return this.authApi.register(payload).pipe(
      map(() => ctx.dispatch(new Actions.RegisterSuccess())),
      catchError((error) => ctx.dispatch(new Actions.RegisterError(error)))
    );
  }

  @Action(Actions.RegisterSuccess)
  public onRegisterSuccess(ctx: StateContext<AppStateModel>) {
    this.analytics.sendEvent({
      eventCategory: 'Registrations',
      eventActions: 'Success',
    });
    ctx.dispatch(new Actions.SuccessMessage('register.registration_success'));
    ctx.dispatch(new Navigate([`/login`]));
  }

  @Action(Actions.RegisterError)
  public onRegisterError(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.RegisterError
  ) {
    this.analytics.sendEvent({
      eventCategory: 'Registrations',
      eventActions: 'Failure',
      eventLabel: `${payload.statusText} - ${payload.message}`,
    });
    ctx.dispatch(new Actions.ErrorMessage('register.registration_error'));
  }

  /**
   * Reset password
   */
  @Action(Actions.ResetPassword)
  public onResetPassword(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.ResetPassword
  ) {
    return this.usersApiService.resetPassword(payload).pipe(
      tap(() => {
        ctx.dispatch(
          new Actions.SuccessMessage('reset_password.update_success')
        );
        ctx.dispatch(new Navigate([`/login`]));
      })
    );
  }

  /**
   * Forgot password
   */
  @Action(Actions.ForgotPassword)
  public onForgotPassword(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.ForgotPassword
  ) {
    return this.authApi.forgotPassword(payload).pipe(
      tap(() => ctx.dispatch(new Actions.ForgotPasswordSuccess())),
      catchError(() => ctx.dispatch(new Actions.ForgotPasswordError()))
    );
  }

  @Action(Actions.ForgotPasswordSuccess)
  public onForgotPasswordSuccess(ctx: StateContext<AppStateModel>) {
    ctx.dispatch(new Actions.SuccessMessage('forgot_password.send_success'));
    ctx.dispatch(new Navigate([`/login`]));
  }

  @Action(Actions.ForgotPasswordError)
  public ForgotPasswordError(ctx: StateContext<AppStateModel>) {
    ctx.dispatch(new Actions.ErrorMessage('forgot_password.send_error'));
  }

  /**
   * Current user
   */
  @Action(Actions.SetCurrentUser)
  public setCurrentUser(
    { patchState }: StateContext<AppStateModel>,
    { payload }: Actions.SetCurrentUser
  ) {
    patchState({ user: payload });
  }

  @Action(Actions.PatchCurrentUser)
  public onPatchCurrentUser(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.PatchCurrentUser
  ) {
    const state = ctx.getState();
    ctx.patchState({ user: Object.assign(state.user, payload) });
  }

  @Action(Actions.ClearCurrentUser)
  public clearCurrentUser({ patchState }: StateContext<AppStateModel>) {
    patchState({ user: null });
  }

  @Action(Actions.AddSubscriptionToSession)
  public onAddSubscriptionToSession(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.AddSubscriptionToSession
  ) {
    const state = ctx.getState();
    const { paymentToken, countyId, subscriptionToken } = payload;
    ctx.patchState({
      user: {
        ...state.user,
        paymentToken,
      },
      counties: [...state.counties, countyId],
      countySubscriptions: [
        ...state.countySubscriptions,
        {
          countyId,
          subscriptionToken,
        },
      ],
    });
  }

  @Action(Actions.AcceptTermsOfService)
  public onAcceptTermsOfService(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    return this.usersApiService
      .patchUser({ id: state.user.id, termsAccepted: true })
      .pipe(
        tap(() =>
          ctx.dispatch(new Actions.PatchCurrentUser({ termsAccepted: true }))
        ),
        map(() => ctx.dispatch(new Navigate([`/counties`])))
      );
  }

  @Action(Actions.ChangePassword)
  public onChangePassword(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.ChangePassword
  ) {
    return this.usersApiService.changePassword(payload).pipe(
      tap(() =>
        ctx.dispatch(new Actions.SuccessMessage('app.change_password_success'))
      ),
      map(() => ctx.dispatch(new Actions.Logout())),
      catchError(() =>
        ctx.dispatch(new Actions.ErrorMessage('app.change_password_error'))
      )
    );
  }

  @Action(Actions.ChangeStripeToken)
  public onChangeStripeToken(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.ChangeStripeToken
  ) {
    ctx.dispatch(new Actions.ShowSpinner());
    return this.usersApiService.changeStripeToken(payload).pipe(
      tap(() =>
        ctx.dispatch(new Actions.SuccessMessage('app.card_change_success'))
      ),
      catchError((response) =>
        ctx.dispatch(
          new Actions.ErrorMessage('app.card_change_error', {
            error: response.error.message,
          })
        )
      ),
      finalize(() => ctx.dispatch(new Actions.HideSpinner()))
    );
  }

  @Action(Actions.UpdateAccount)
  public onUpdateAccount(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.UpdateAccount
  ) {
    return this.usersApiService.updateUser(payload).pipe(
      tap((response) =>
        ctx.dispatch([
          new Actions.SuccessMessage('account.update_success'),
          new Actions.PatchCurrentUser(response),
        ])
      ),
      catchError((response) =>
        ctx.dispatch(
          new Actions.ErrorMessage('account.update_error', {
            error: response.error.message,
          })
        )
      )
    );
  }

  /**
   * Current State
   */
  @Action(Actions.SetCurrentState)
  public setCurrentState(
    { patchState }: StateContext<AppStateModel>,
    { payload }: Actions.SetCurrentState
  ) {
    patchState({ state: payload });
  }

  @Action(Actions.ClearCurrentState)
  public clearCurrentState({ patchState }: StateContext<AppStateModel>) {
    patchState({ state: null });
  }

  /**
   * Current County
   */
  @Action(Actions.SetCurrentCounty)
  public setCurrentCounty(
    { patchState }: StateContext<AppStateModel>,
    { payload }: Actions.SetCurrentCounty
  ) {
    patchState({ county: payload });
  }

  @Action(Actions.ClearCurrentCounty)
  public clearCurrentCounty({ patchState }: StateContext<AppStateModel>) {
    patchState({ county: null });
  }

  /**
   * Messages
   */

  @Action(Actions.SuccessMessage)
  public onSuccessMessage(
    ctx: StateContext<AppStateModel>,
    { translateKey, params }: Actions.SuccessMessage
  ) {
    this.translate
      .get(translateKey, params)
      .pipe(first())
      .subscribe((message) => {
        this.notifier.notify('success', message);
      });
  }

  @Action(Actions.InfoMessage)
  public onInfoMessage(
    ctx: StateContext<AppStateModel>,
    { translateKey, params }: Actions.InfoMessage
  ) {
    return this.translate
      .get(translateKey, params)
      .pipe(first())
      .subscribe((message) => {
        this.notifier.notify('info', message);
      });
  }

  @Action(Actions.ErrorMessage)
  public onErrorMessage(
    ctx: StateContext<AppStateModel>,
    { translateKey, params }: Actions.ErrorMessage
  ) {
    return this.translate
      .get(translateKey, params)
      .pipe(first())
      .subscribe((message) => {
        this.notifier.notify('error', message);
      });
  }

  @Action(Actions.WarningMessage)
  public onWarningMessage(
    ctx: StateContext<AppStateModel>,
    { translateKey, params }: Actions.WarningMessage
  ) {
    return this.translate
      .get(translateKey, params)
      .pipe(first())
      .subscribe((message) => {
        this.notifier.notify('warning', message);
      });
  }

  /**
   * Websocket actions
   */

  @Action(Actions.UserLocked)
  public onUserLocked(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.UserLocked
  ) {
    const state = ctx.getState();
    if (state.user.id === payload.id) {
      ctx.dispatch([
        new Actions.ErrorMessage('app.your_account_locked'),
        new Actions.Logout(),
      ]);
    }
  }

  @Action(Actions.DuplicateLogin)
  public onDuplicateLogin(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.DuplicateLogin
  ) {
    const state = ctx.getState();
    if (payload.indexOf(state.sessionId) !== -1) {
      ctx.dispatch([
        new Actions.ErrorMessage('app.duplicate_login'),
        new Actions.Logout(),
      ]);
    }
  }

  @Action(Actions.SubscriptionExpiration)
  public onSubscriptionExpiration(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      counties: state.counties.filter((c) => c !== state.county.id),
      countySubscriptions: state.countySubscriptions.filter(
        (s) => s.countyId !== state.county.id
      ),
    });
    ctx.dispatch([
      new Actions.InfoMessage('app.expired_subscription'),
      new Navigate([`/counties/${state.county.id}/subscriptions`]),
    ]);
  }

  @Action(Actions.LogOutUsersByType)
  public onLogOutUsersByType(
    ctx: StateContext<AppStateModel>,
    { payload }: Actions.LogOutUsersByType
  ) {
    const state = ctx.getState();
    if (payload.indexOf(state.user.role) !== -1) {
      ctx.dispatch([
        new Actions.InfoMessage('app.forced_logout_message'),
        new Actions.Logout(),
      ]);
    }
  }

  @Action(Actions.DisconnectUsers)
  public onDisconnectUsers(ctx: StateContext<AppStateModel>) {
    const action = {
      type: Actions.LogOutUsersByType.type,
      payload: [UserRoles.viewOnly, UserRoles.curator],
    };
    ctx.dispatch(new SendWebSocketMessage(action));
  }
}
