import { NotifierOptions } from 'angular-notifier-updated';

export const initialCrudState = {
  collection: [],
  selected: null,
  itemsPerPage: 15,
  currentPage: 1,
  searchText: null,
  sortKey: null,
  sortReversed: false,
  searchColumns: [],
  filters: {},
  appliedFilters: {},
};

export const initialAppState = {
  token: null,
  sessionId: null,
  version: null,
  showSpinner: false,
  showMask: false,
  county: null,
  state: null,
  user: null,
  counties: [],
  countySubscriptions: [],
};

export interface FilterDefinition {
  id?: number;
  type?: string; // AlertType;
  param: string;
  value: any;
  label: string;
  shortcut?: boolean;
  dropdown?: boolean;
}

export interface FilterDefinitionDict {
  [key: string]: FilterDefinition;
}

export const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 8000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

export enum SortDirectionOptions {
  ascending = 'asc',
  descending = 'desc',
}
