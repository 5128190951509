export const REQUIRED_ERROR = {
  name: 'required',
  text: 'app.field_required',
  rules: ['touched']
};

export const EMAIL_FORMAT_ERROR = {
  name: 'email',
  text: 'app.email_invalid',
  rules: ['touched']
};

export const PASSWORD_LENGTH_ERROR = {
  name: 'minlength',
  text: 'app.password_length',
  rules: ['touched']
};

export const NUMBER_PATTERN = /^[0-9]*$/;
export const NUMBER_PATTERN_ERROR = {
  name: 'pattern',
  text: 'app.field_numbers_only',
  rules: ['touched']
};

export const PRICE_PATTERN = /^(?:[0-9])*(?:\.[0-9]{1,2})?$/;
export const PRICE_PATTERN_ERROR = {
  name: 'pattern',
  text: 'app.field_price_format',
  rules: ['touched']
};
