import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {

  transform(items: Array<any>, config: PaginationConfig): any {

    if (!items.length) { return null; }

    const start = (config.currentPage - 1) * config.itemsPerPage; // + 1;
    const total = items.length;

    let end = total;

    if (config.itemsPerPage < total) {
      end = config.itemsPerPage * config.currentPage;
      if (end > total) {
        end = total;
      }
    }

    return items.slice(start, end);
  }

}

export interface PaginationConfig {
  itemsPerPage: number;
  currentPage: number;
}
