import { UserRole, UserRoles } from '@app/shared/users/users.constants';
import { County } from '../counties/counties.models';

export class User {
  public id?: string | null = null;
  public paymentToken?: string;
  public name?: string;
  public email?: string;
  public phone?: string;
  public organization?: string;
  public locked?: boolean;
  public termsAccepted?: boolean;
  public role?: UserRole;
  public billable?: boolean;
  public countyId?: string;
  public county?: County = {};
  public lastLogin?: string;

  public $isAdmin?: boolean;
  public $isCurator?: boolean;
  public $isViewOnly?: boolean;
  public $isCourthouseUser?: boolean;
  public $startUrl?: string;

  /** @deprecated */
  public emailValidated?: boolean;

  constructor(user?: User) {
    if (user) {
      const defaults = {
        locked: false,
        termsAccepted: false,
        role: UserRoles.viewOnly,
        billable: true,
      };
      const $meta = {
        $isAdmin: user.role === UserRoles.admin,
        $isCurator: user.role === UserRoles.curator,
        $isViewOnly: user.role === UserRoles.viewOnly,
        $isCourthouseUser: !!user.countyId,
        $startUrl: getStartUrl(user),
      };
      Object.assign(this, defaults, user, $meta);
    }
  }
}

function getStartUrl(user: User): string {
  return user.countyId
    ? `/counties/${user.countyId}/assets`
    : user.role === UserRoles.admin
    ? '/users'
    : '/counties';
}
