import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { environment } from '@env/environment';
import { collapseExpand } from '@app/shared/animations/animations';

@Component({
  selector: 'vault-debug-data',
  templateUrl: './debug-data.component.html',
  styleUrls: ['./debug-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseExpand],
})
export class DebugDataComponent {
  @Input() public label = 'Data';

  public closed = true;

  public toggle(): void {
    this.closed = !this.closed;
  }

  public get icon(): string {
    return this.closed ? 'plus' : 'minus';
  }

  public get isDevMode(): boolean {
    return environment.showDebugData;
  }
}
