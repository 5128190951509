import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { AppStateModel } from '@app/shared';
import * as Actions from '@app/app.actions';

@Injectable({
  providedIn: 'root'
})
export class CanActivateIfAdminGuard implements CanActivate {

  constructor(
    private store: Store,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree {

    const ctx = this.store.selectSnapshot<AppStateModel>( state => state.app );

    if (ctx.user && ctx.user.$isAdmin) {
      return true;
    } else {
      this.store.dispatch( new Actions.ErrorMessage('app.access_prohibited') );
      this.store.dispatch( new Actions.Logout() );
      return false;
    }

  }

}
