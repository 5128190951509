import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

  transform(items: Array<any>, sortKey: string, reversed: boolean = false) {
    if (items) {
      const sortedItems = items.sort((a, b) =>
        this.collator.compare(a[sortKey], b[sortKey])
      );

      return reversed ? sortedItems.reverse() : sortedItems;
    }

    return;
  }
}
