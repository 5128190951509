import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AppStateModel } from '@app/shared';

@Injectable({
  providedIn: 'root'
})
export class RedirectToSubscriptionsGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree {

    const ctx = this.store.selectSnapshot<AppStateModel>( state => state.app );
    const isViewOnly = ctx.user.$isViewOnly;
    const notSubscribed = ctx.counties.indexOf(ctx.county.id) === -1;
    const isCountyUser = ctx.user.countyId === ctx.county.id;
    const billable = ctx.user.billable;

    /**
     * Send to /subscriptions if a viewOnly user
     * and not subscribed or a county user
     */
    if ( billable && isViewOnly && notSubscribed && !isCountyUser ) {
      return this.router.parseUrl(`/counties/${ctx.county.id}/subscriptions`);
    } else {
      return true;
    }

  }

}
