import { Component, Output, EventEmitter, HostBinding, ChangeDetectionStrategy } from '@angular/core';

import { ListViewComponent } from '@app/shared/components/list-view/list-view.component';

@Component({
  selector: 'vault-list-picker',
  template: '<!--placeholder-->',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPickerComponent extends ListViewComponent {

  @HostBinding('class') componentClass = 'vault-list-picker';

  @Output() public select: EventEmitter<any> = new EventEmitter();
  @Output() public delete: EventEmitter<any> = new EventEmitter();

  public selectRecord(record: any): void {
    this.select.emit(record);
  }

  public deleteRecord(record: any): void {
    this.delete.emit(record);
  }

}
