<vault-page-header class="drawer">
  <h1 [innerHtml]="title"></h1>
</vault-page-header>

<div class="vault-flex-content">
  <div class="vault-content auto-scroll">
    <div class="container-fluid">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<vault-actions-footer>
  <ng-content select=".actions-primary"></ng-content>
  <div class="secondary">
    <ng-content select=".actions-secondary"></ng-content>
  </div>
</vault-actions-footer>