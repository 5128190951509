import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, catchError } from 'rxjs/operators';

import { County } from '@app/shared/counties/counties.models';
import { LoggerService } from '@app/core';
import { CountyService } from './county.service';

@Injectable({
  providedIn: 'root',
})
export class CountiesApiService {
  private get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(private http: HttpClient, private countyService: CountyService) {}

  public getAllCounties(): Observable<County[]> {
    return this.http.get<County[]>(`${this.apiUrl}/counties`).pipe(
      map((counties) =>
        counties.map((county) => this.countyService.getCountyModel(county))
      ),
      catchError(this.handleError)
    );
  }

  public getCountiesByStateId(stateId: string): Observable<County[]> {
    return this.http
      .get<County[]>(`${this.apiUrl}/counties?state=${stateId}`)
      .pipe(catchError(this.handleError));
  }

  public updateCounty(county: County): Observable<County> {
    return this.http
      .put<County>(`${this.apiUrl}/counties/${county.id}`, county)
      .pipe(
        map((cty) => this.countyService.getCountyModel(cty)),
        catchError(this.handleError)
      );
  }

  public getCountyById(id: string): Observable<County> {
    return this.http.get<County>(`${this.apiUrl}/counties/${id}`).pipe(
      map((county) => this.countyService.getCountyModel(county)),
      catchError(this.handleError)
    );
  }

  /**
   * Search for assets (books/indices/plats/maps etc)
   */
  public propertySearch(
    countyId: string,
    property: string,
    term: string,
    limit: number = 15
  ): Observable<{ items: string[]; totalItems: number }> {
    if (!term) {
      return of({ items: [], totalItems: 0 });
    }
    return this.http
      .get<{ items: string[]; totalItems: number }>(
        `${this.apiUrl}/counties/${countyId}/searchkey/${property}`,
        {
          params: { term, limit: `${limit}` },
        }
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    LoggerService.error(error);
    return observableThrowError(error);
  }
}
