import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'vault-list-view',
  template: '<!--placeholder-->',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListViewComponent {
  @Input() public collection: any[];
  @Input() public itemsPerPage: number;
  @Input() public currentPage: number;
  @Input() public totalItems: number;

  @Output() public sort: EventEmitter<string> = new EventEmitter();
  @Output() public pageChanged: EventEmitter<number> = new EventEmitter();

  public sortBy(attribute: string): void {
    this.sort.emit(attribute);
  }

  public changePage(pg): void {
    this.pageChanged.emit(pg);
  }

  public identifyRecord(index: number, record: any): string {
    return record.id;
  }

  public get paginationConfig(): PaginationConfig {
    return {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      totalItems: this.totalItems,
    };
  }
}

export interface PaginationConfig {
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
}
